<template>
  <div>
    <k-panelbar
      class="custom"
      expandMode="multiple"
      :items="PANEL_BAR_ITEMS"
      :expanded="PANEL_BAR_ITEMS.map(({ id }) => id)"
    >
      <!-- Search Panel -->
      <template v-slot:search>
        <li>
          <span class="k-link" @click="() => onSelect(PANEL_BAR_KEYS.SEARCH)">
            <span class="k-panelbar-item-text">
              <div v-if="panelBar === PANEL_BAR_KEYS.SEARCH" class="row">
                <div class="col-1 d-flex align-items-center">
                  <i
                    @click="doSearch"
                    class="k-icon k-i-search k-icon-24 k-link text-primary"
                  ></i>
                </div>
                <div class="col-11 ps-1">
                  <k-inputs
                    type="text"
                    class="unset-background custom-search-field border-0 mb-0"
                    v-on:keyup.enter="doSearch"
                    v-model="searchKeyword"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div v-else class="v-row col-12 d-flex align-items-center">
                <i class="k-icon k-i-search k-icon-24 me-2"></i> Search
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down': panelBar !== PANEL_BAR_KEYS.SEARCH,
                  'k-i-arrow-chevron-up': panelBar === PANEL_BAR_KEYS.SEARCH
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.SEARCH)"
            ></span>
          </span>
          <div
            class="row m-0 content"
            v-if="panelBar === PANEL_BAR_KEYS.SEARCH"
          >
            <div class="col-12 px-0" v-if="searchTotal > 0">
              <span class="ps-4">{{ keyword }}</span>
              <span class="dashboard--color1">({{ searchTotal }})</span>
              <div class="grid-search custom-scrollbar px-4">
                <div
                  v-for="item in searchResponse"
                  :key="item.ticker_region"
                  @click="onResultClick(item)"
                  class="d-flex align-items-center k-link border border-light rounded my-2 p-2"
                >
                  <div class="col-9 dashboard--color3 text-truncate">
                    {{ item.isin === 'missing' ? 'N/A' : item.isin }}
                    {{ item.name }}
                  </div>
                  <div
                    class="col-3 dashboard--color2 d-flex justify-content-end"
                  >
                    {{ item.exchange_country }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-12 grid-search-empty">
              <div
                class="d-flex justify-content-center align-items-center text-center px-6 h-100"
              >
                <span v-if="keyword.length < 1"
                  >Please input at least 1 character in order to search for
                  specific items.</span
                >
                <span v-else-if="searchTotal < 1 && keyword.length > 0">
                  <div class="d-flex justify-content-between warning">
                    <div class="warning-icon">
                      <icon-warning></icon-warning>
                    </div>
                    <div class="warning-message">
                      <template v-if="!isResponseOverloaded">
                        There are no results to be displayed. Please adjust your
                        keyword.
                      </template>
                      <template v-else
                        >Too many results. Please narrow your search!</template
                      >
                    </div>
                  </div></span
                >
              </div>
            </div>
          </div>
        </li>
      </template>
      <!-- Watchlists Panel -->
      <template v-slot:watchLists>
        <li>
          <span
            class="k-link"
            @click="() => onSelect(PANEL_BAR_KEYS.WATCH_LISTS)"
          >
            <span class="k-panelbar-item-text">
              <div class="row">
                <div class="col-5 d-flex align-items-center">
                  <i
                    class="k-icon k-i-star-outline k-icon-24 me-2"
                    :class="panelBar == 1 ? 'text-primary' : ''"
                  ></i>
                  Watchlists
                </div>
                <div
                  v-if="panelBar == PANEL_BAR_KEYS.WATCH_LISTS"
                  class="col-7 text-end"
                >
                  <k-button
                    class="custom-button-icon"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="downloadWatchlist"
                    :disabled="selectedWatchlists.length == 0"
                    ><icon-file-arrow-down
                  /></k-button>
                  <k-button
                    class="custom-button-icon"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="confirmDeleteWatchlistDialog = true"
                    :disabled="selectedWatchlists.length == 0"
                    ><icon-trash
                  /></k-button>
                  <k-button
                    class="ms-2"
                    theme-color="primary"
                    @click="$refs.watchlistFile.click()"
                    >Upload Watchlist</k-button
                  >
                </div>
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down':
                    panelBar !== PANEL_BAR_KEYS.WATCH_LISTS,
                  'k-i-arrow-chevron-up':
                    panelBar === PANEL_BAR_KEYS.WATCH_LISTS
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.WATCH_LISTS)"
            ></span>
          </span>
          <div
            class="grid-watchlist content"
            v-if="panelBar == PANEL_BAR_KEYS.WATCH_LISTS"
          >
            <div class="row m-0">
              <div
                class="col-12 tab-filter watchlist-list custom-scrollbar p-4"
              >
                <div
                  v-for="(item, idx) in $store.state.watchlists"
                  :key="idx"
                  class="d-flex align-items-center custom secondary unset-background mb-2"
                >
                  <input
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    type="checkbox"
                    :id="item.name"
                    :value="item.name"
                    v-model="selectedWatchlists"
                  />
                  <div
                    class="d-flex justify-content-between align-items-center border rounded w-100 ms-3 px-2"
                  >
                    <span class="text-white" v-text="item.name"></span>
                    <span
                      class="unset-min-width k-button k-button-link"
                      @click="loadWatchlist(item)"
                      >Load List</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 grid-watchlist_content">
              <div class="col-12 bg-info text-white">
                {{ watchlist.name }}
              </div>
              <div class="col-12 px-0 grid-watchlist_content_table">
                <k-grid
                  id="gridWatchlist"
                  class="p-0 m-0"
                  :data-items="watchlist.companies"
                  :columns="columnWatchlists"
                  @rowclick="onRowClickWatchlist"
                >
                  <k-grid-no-rec class="mt-6">
                    <b>There are no watchlists to be displayed.</b>
                    <p>Please load your watchlists.</p>
                  </k-grid-no-rec>
                  <template v-slot:scoreFormat="{ props }">
                    <td>
                      {{ Math.round(props.dataItem[props.field] * 100) }}%
                    </td>
                  </template>
                  <template v-slot:actionTemplate="{ props }">
                    <td colspan="1">
                      <div class="d-flex flex-row align-items-center">
                        <k-button
                          class="custom-button grid-btn m-0"
                          theme-color="inherit"
                          fill-mode="solid"
                          rounded="full"
                          @click="drawerVisible = true"
                        >
                          <k-tooltip
                            id="tooltip"
                            :title="hoverWatchlist(props.dataItem)"
                            :key="hoverWatchlist(props.dataItem)"
                            :position="'left'"
                          >
                            <span class="k-icon k-i-star text-white"></span>
                          </k-tooltip>
                        </k-button>
                      </div>
                    </td>
                  </template>
                </k-grid>
              </div>
            </div>
          </div>
        </li>
      </template>
      <!-- Filters Panel -->
      <template v-slot:filters>
        <li class="k-state-active">
          <span class="k-link" @click="() => onSelect(PANEL_BAR_KEYS.FILTERS)">
            <span class="k-panelbar-item-text">
              <div class="row">
                <div class="col-5 d-flex align-items-center">
                  <i
                    class="k-icon k-i-saturation k-icon-24 me-2"
                    :class="panelBar == 2 ? 'text-primary' : ''"
                  ></i>
                  Filters
                </div>
                <div
                  v-if="panelBar == PANEL_BAR_KEYS.FILTERS"
                  class="col-7 text-end"
                >
                  <span
                    class="unset-min-width k-button k-button-link me-3"
                    :class="selectedTab == 0 ? 'active-tab-filter' : ''"
                    @click="selectedTab = 0"
                    >Filter</span
                  >
                  <span
                    class="unset-min-width k-button k-button-link"
                    :class="selectedTab == 1 ? 'active-tab-filter' : ''"
                    @click="selectedTab = 1"
                    >Saved Filters</span
                  >
                </div>
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down': panelBar !== PANEL_BAR_KEYS.FILTERS,
                  'k-i-arrow-chevron-up': panelBar === PANEL_BAR_KEYS.FILTERS
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.FILTERS)"
            ></span>
          </span>
          <div
            class="sidebar-filter content"
            v-if="panelBar == PANEL_BAR_KEYS.FILTERS"
          >
            <div class="panel-active">
              <div
                :style="selectedTab == 0 ? '' : 'display: none;'"
                class="container px-2 tab-filter"
              >
                <div class="row ps-1 m-0 pb-0" v-if="showAdvFilter">
                  <!-- Rating ------------------ Risk Score -->
                  <div class="row p-0">
                    <div class="col-8 pe-0">
                      <span class="filterfont">
                        Exchange Country
                        <span style="color: red; font-size: 14px">*</span>
                      </span>
                      <span v-if="count.exchangeCount" class="filterCountfont">
                        ({{ count.exchangeCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.exchange.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.exchange"
                        placeholder="Select"
                        :source="exchange"
                        :dropDownName="'EXC'"
                        :useLoader="loaderImage.exc"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="exchange_country"
                        dataItemKey="exchange_countryID"
                        required
                        preventEmitWhenUnselectAll
                      ></k-custom-multiselect>
                      <span v-if="statusExchange" class="errorfont">
                        {{ statusExchange }}</span
                      >
                    </div>

                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont">Risk score</span>
                      <span v-if="count.riskScoreCount" class="filterCountfont"
                        >({{ count.riskScoreCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max -->
                        <span class="rangefontSize"
                          >{{ formatedNumber(riskScoreDefault[0])
                          }}<span
                            v-if="riskScore[0] != riskScoreDefault[0]"
                            class="text-white"
                            >({{ formatedNumber(riskScore[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="riskScore[1] != riskScoreDefault[1]"
                            class="text-white"
                            >({{ formatedNumber(riskScore[1]) }})</span
                          >{{ formatedNumber(riskScoreDefault[1]) }}</span
                        >
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(riskScore[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(riskScore[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeRiskScore"
                        id="riskScoreSlider"
                        :class="{ 'active-slider': sliderActiveRs }"
                        class="px-2"
                        v-model="riskScore"
                        :min="riskScoreDefault[0]"
                        :max="riskScoreDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                  </div>
                  <!-- Sector - Industry Group - Auditor -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont"> Sector</span>
                      <span v-if="count.sectorCount" class="filterCountfont">
                        ({{ count.sectorCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.sectorId.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.sectorId"
                        placeholder="Select"
                        :source="sector"
                        :dropDownName="'SEC'"
                        :useLoader="loaderImage.sec"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="sector_name"
                        dataItemKey="sectorID"
                      />
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Industry Group </span>
                      <span v-if="count.industryCount" class="filterCountfont">
                        ({{ count.industryCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-0"
                        :class="
                          filter.industryGroupId.length > 0
                            ? 'active-drop-down'
                            : ''
                        "
                        v-model="filter.industryGroupId"
                        placeholder="Select"
                        :source="industry"
                        :dropDownName="'IG'"
                        :useLoader="loaderImage.ig"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="industry_group_name"
                        dataItemKey="industry_groupID"
                      />
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Auditor </span>
                      <span v-if="count.auditorCount" class="filterCountfont">
                        ({{ count.auditorCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-right"
                        :class="
                          filter.auditorId.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.auditorId"
                        placeholder="Select"
                        :source="auditor"
                        :dropDownName="'AUD'"
                        :useLoader="loaderImage.aud"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="auditor_name"
                        dataItemKey="auditorID"
                      />
                    </div>
                  </div>
                  <!-- Domicile - Exchange - Incorporation -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont">Rating</span>
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.rating.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.rating"
                        placeholder="Select"
                        :source="rating"
                        :dropDownName="'RAT'"
                        :useLoader="loaderImage.rat"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="rating"
                        dataItemKey="rating"
                      />
                    </div>

                    <div class="col-4 pe-0 ps-1">
                      <span class="filterfont"> Domicile </span>
                      <span v-if="count.domicileCount" class="filterCountfont">
                        ({{ count.domicileCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-0"
                        :class="
                          filter.domicile.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.domicile"
                        placeholder="Select"
                        :source="domicile"
                        :dropDownName="'DOM'"
                        :useLoader="loaderImage.dom"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="domicile_country"
                        dataItemKey="domicile_country"
                      ></k-custom-multiselect>
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Incorporation </span>
                      <span
                        v-if="count.incorporationCount"
                        class="filterCountfont"
                        >({{ count.incorporationCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-right"
                        :class="
                          filter.incorporation.length > 0
                            ? 'active-drop-down'
                            : ''
                        "
                        v-model="filter.incorporation"
                        placeholder="Select"
                        :source="incorporation"
                        :dropDownName="'INC'"
                        :useLoader="loaderImage.inc"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="incorporation_country"
                        dataItemKey="incorporation_country"
                      ></k-custom-multiselect>
                    </div>
                  </div>
                  <!-- Status - 1 Year Change - Market Cap Decile -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont"> Status </span>
                      <span v-if="count.statusCount" class="filterCountfont">
                        ({{ count.statusCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.status.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.status"
                        placeholder="Select"
                        :source="status"
                        :dropDownName="'STA'"
                        :useLoader="loaderImage.sta"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="trading_status"
                        dataItemKey="trading_status"
                      ></k-custom-multiselect>
                    </div>
                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont"> 1 Year Change </span>
                      <span
                        v-if="count.oneYearChangeCount"
                        class="filterCountfont"
                      >
                        ({{ count.oneYearChangeCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max -->
                        <span class="rangefontSize"
                          >{{ formatedNumber(oneYearChangeDefault[0]) }}
                          <span
                            v-if="oneYearChange[0] != oneYearChangeDefault[0]"
                            class="text-white"
                            >({{ formatedNumber(oneYearChange[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="oneYearChange[1] != oneYearChangeDefault[1]"
                            class="text-white"
                            >({{ formatedNumber(oneYearChange[1]) }})</span
                          >
                          {{ formatedNumber(oneYearChangeDefault[1]) }}
                        </span>
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(oneYearChange[0]) ? formatedNumber(oneYearChange[0]) : formatedNumber(oneYearChangeDefault[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(oneYearChange[1]) ? formatedNumber(oneYearChange[1]) : formatedNumber(oneYearChangeDefault[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeOneYearChange"
                        v-model="oneYearChange"
                        :class="{ 'active-slider': sliderActiveOyc }"
                        class="px-2"
                        id="oneYearChangeSlider"
                        :min="oneYearChangeDefault[0]"
                        :max="oneYearChangeDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont">
                        Market Cap Decile - 1=Large
                      </span>
                      <span
                        v-if="count.marketCapDecileCount"
                        class="filterCountfont"
                      >
                        ({{ count.marketCapDecileCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max || (selected value)-->
                        <span class="rangefontSize"
                          >{{ formatedNumber(marketCapDecileDefault[0])
                          }}<span
                            v-if="
                              marketCapDecile[0] != marketCapDecileDefault[0]
                            "
                            class="text-white"
                            >({{ formatedNumber(marketCapDecile[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="
                              marketCapDecile[1] != marketCapDecileDefault[1]
                            "
                            class="text-white"
                            >({{ formatedNumber(marketCapDecile[1]) }})</span
                          >{{ formatedNumber(marketCapDecileDefault[1]) }}</span
                        >
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(marketCapDecile[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(marketCapDecile[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeMarketCapDecile"
                        v-model="marketCapDecile"
                        :class="{ 'active-slider': sliderActiveMcd }"
                        class="px-2"
                        id="marketCapDecileSlider"
                        :min="marketCapDecileDefault[0]"
                        :max="marketCapDecileDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                  </div>
                </div>
                <div class="row ps-1 m-0 pb-2">
                  <div class="row p-0">
                    <div class="col-4 pe-0 align-items-end">
                      <span class="filterfont">Year</span>
                      <!-- <span class="filterCountfont"> *</span> -->
                      <ComboBox
                        class="dropdown-rounded-left picker-with-bg custom-year"
                        :class="lastSelectedYear ? 'active-drop-down' : ''"
                        v-model="tempYear"
                        :data-items="year"
                        placeholder="Select"
                        :clear-button="false"
                        :loading="loaderImage.year"
                        :disabled="loaderImage.year"
                        @change="saveSelectedYear()"
                      >
                      </ComboBox>
                    </div>
                    <div
                      class="col-8 pe-0 d-flex align-items-end justify-content-end"
                    >
                      <!-- <div class="d-flex justify-content-end align align-items-center bg-white"> -->
                      <div class="pe-1">
                        <k-button
                          class="btn-without-min-width"
                          :fill-mode="'outline'"
                          theme-color="#8698ac"
                          @click.stop="clearAllFilter"
                        >
                          Clear
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <k-button
                          theme-color="secondary"
                          @click.stop="saveFilterDialog = true"
                        >
                          Save Filter
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <k-button
                          theme-color="primary"
                          @click.stop="checkAvailableYear()"
                          id="filterButton"
                        >
                          Apply Filter
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <a
                          v-on:click="advFilter()"
                          class="filterfont text-decoration-underline"
                          v-text="advFilterText"
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :style="selectedTab == 1 ? '' : 'display: none;'"
                class="tab-filter"
              >
                <div class="row m-0">
                  <div
                    class="col-12 px-4 py-2 d-flex justify-content-between align-items-center"
                  >
                    <span>Saved Filters</span>
                    <k-button
                      :disabled="selectedSavedFilters.length == 0"
                      @click="confirmDeleteSaveFilterDialog = true"
                      class="custom-button-icon"
                      theme-color="secondary"
                      :size="'small'"
                      fill-mode="solid"
                      rounded="full"
                      ><icon-trash
                    /></k-button>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-12 saved-filter custom-scrollbar p-4">
                    <div
                      v-for="(filter, idx) in $store.state.filters"
                      :key="idx"
                      class="d-flex align-items-center custom secondary unset-background mb-2"
                    >
                      <input
                        class="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        :id="filter.name"
                        :value="filter.name"
                        v-model="selectedSavedFilters"
                      />
                      <div
                        class="d-flex justify-content-between align-items-center border rounded w-100 ms-3 px-2"
                      >
                        <span class="text-white" v-text="filter.name"></span>
                        <span
                          class="unset-min-width k-button k-button-link"
                          @click="loadSavedFilter(filter)"
                          >Load Filter</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ps-0 m-0 pb-0">
              <k-grid
                id="gridFilter"
                class="grid-filter p-0 m-0 grid-filter-min"
                :data-items="items"
                :columns="columns"
                :selected-field="selectedField"
                :sortable="true"
                :sort="sort"
                @sortchange="sortChangeHandler"
                :skip="skip"
                :take="take"
                :total="total"
                :pageable="pageable"
                :page-size="pageSize"
                @pagechange="pageChangeHandler"
                @rowclick="onRowClick"
              >
                <k-grid-no-rec class="mt-6">
                  <b>There are no results to be displayed.</b>
                  <p>Please adjust your filters.</p>
                </k-grid-no-rec>
                <template v-slot:yearTemplate>
                  <div class="d-flex align-items-center">
                    Risk {{ filter.year }}
                  </div>
                </template>

                <template v-slot:scoreFormat="{ props }">
                  <td class="ps-4">
                    {{ Math.round(props.dataItem[props.field] * 100) }}%
                  </td>
                </template>
                <template v-slot:actionHeaderTemplate></template>
                <template v-slot:actionTemplate="{ props }">
                  <td colspan="1">
                    <div class="d-flex flex-row align-items-center">
                      <k-button
                        class="custom-button grid-btn m-0"
                        theme-color="inherit"
                        fill-mode="solid"
                        rounded="full"
                        @click="showWatchlistDialog(props.dataItem, $event)"
                      >
                        <span class="k-icon k-i-star-outline"></span>
                      </k-button>
                    </div>
                  </td>
                </template>
              </k-grid>
              <k-button
                :disabled="disableSaveFilter"
                class="fab-filter btn-without-min-width"
                rounded="full"
                @click="downloadFilterResult"
              >
                <icon-file-arrow-down />
                <span>csv</span>
              </k-button>
            </div>
          </div>
        </li>
      </template>
    </k-panelbar>

    <k-dialog
      v-if="downloadDialog"
      :title="' '"
      @close="downloadDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12 text-center">
          <div class="title fw-bold">
            Are you sure you want to download this item?
          </div>
          <div class="fw-regular">
            CSV downloads are limited to {{ filterSizeRecord }} results.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="downloadDialog = false"
            >Close</k-button
          >
          <k-button class="me-2" theme-color="primary" @click="downloadConfirm"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="downloadSaveDialog"
      :title="' '"
      @close="downloadSaveDialog = false"
      appendTo="body"
    >
      <div class="d-flex mb-3 justify-content-center">
        <div class="col-12 text-center">
          <div class="fw-bold pb-2">Download CSV</div>
          <k-inputs
            v-model="fileNameFilter"
            placeholder="Name your CSV file"
          ></k-inputs>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="downloadSaveDialog = false"
          >Close</k-button
        >
        <k-button class="me-2" theme-color="primary" @click="downloadResult"
          >Confirm</k-button
        >
      </div>
    </k-dialog>
    <!-- save Filter -->
    <k-dialog
      v-if="saveFilterDialog"
      :title="' '"
      @close="saveFilterDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <template v-if="filter.name">
            <div class="fw-bold text-center mb-3">Save this filter</div>
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center"
              >
                <span class="content">Update {{ filter.name }}</span>
                <k-button theme-color="secondary" @click="saveFilter('edit')"
                  >Update</k-button
                >
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-12 mb-2">
                <span class="content">Create New Filter</span>
              </div>
              <div class="col-12">
                <k-inputs
                  class="custom-placeholder"
                  v-model="fileNameFilter"
                  placeholder="Filter name"
                ></k-inputs>
              </div>
              <div class="col-8">
                <k-checkbox
                  class="custom"
                  v-model="isCurrentSelection"
                  label="Include current selection"
                  :rounded="null"
                ></k-checkbox>
              </div>
              <div class="col-4 text-end">
                <k-button
                  theme-color="primary"
                  :disabled="!fileNameFilter"
                  @click="saveFilter('add')"
                  >Save Filter</k-button
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-end">
                <k-button
                  fill-mode="outline"
                  @click="(saveFilterDialog = false), (fileNameFilter = null)"
                  >Cancel</k-button
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div class="fw-bold text-center mb-3">New Filter</div>
            <div class="row">
              <div class="col-12">
                <k-inputs
                  class="custom-placeholder"
                  v-model="fileNameFilter"
                  placeholder="Filter name"
                ></k-inputs>
              </div>
              <div class="col-6">
                <k-checkbox
                  class="custom"
                  v-model="isCurrentSelection"
                  label="Include current selection"
                  :rounded="null"
                ></k-checkbox>
              </div>
              <div class="col-6 text-end">
                <k-button
                  class="unset-min-width me-2"
                  fill-mode="outline"
                  @click="(saveFilterDialog = false), (fileNameFilter = null)"
                  >Cancel</k-button
                >
                <k-button
                  theme-color="primary"
                  :disabled="!fileNameFilter"
                  @click="saveFilter('add')"
                  >Save Filter</k-button
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmSaveFilterDialog"
      :title="' '"
      @close="confirmSaveFilterDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold d-flex justify-content-center align-items-center">
            <icon-warning class="text-warning me-2" /> Filter name already
            exists.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="(confirmSaveFilterDialog = false), (fileNameFilter = null)"
            >Cancel</k-button
          >
          <k-button theme-color="error" @click="confirmSaveFilter('replace')"
            >Replace</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmDeleteSaveFilterDialog"
      :title="' '"
      @close="confirmDeleteSaveFilterDialog = false"
      appendTo="body"
    >
      <div class="row">
        <div class="col-12">
          <div class="title d-flex justify-content-center">
            Are you sure you want to delete the filter section, ‘{{
              selectedSavedFilters.toString().replaceAll(',', '’ / ‘')
            }}’?
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="confirmDeleteSaveFilterDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="deleteSavedFilter"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="saveWatchlistDialog"
      :title="' '"
      @close="(saveWatchlistDialog = false), (validWatchlistName = true)"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center mb-3">
            Save {{ selectedItemWatchList.name }}
            {{ selectedItemWatchList.exchange_country }} to watchlist
          </div>
          <hr v-if="$store.state.watchlists.length > 0" />
          <div class="row">
            <div class="col-12 custom-scrollbar" style="max-height: 30vh">
              <div
                v-for="(item, idx) in $store.state.watchlists"
                :key="idx"
                class="d-flex justify-content-between align-items-center border border-secondary rounded"
                :class="idx >= 1 ? 'mt-2' : ''"
              >
                <span class="ms-2" v-text="item.name"></span>
                <k-button
                  v-if="validateTickerWatchlist(item)"
                  type="button"
                  fill-mode="link"
                  @click="removeCompanyFromWatchlist(item)"
                  >Remove</k-button
                >
                <k-button
                  v-else
                  type="button"
                  fill-mode="link"
                  @click="saveWatchlist(item.name, true)"
                  >Add</k-button
                >
              </div>
            </div>
          </div>
          <hr v-if="$store.state.watchlists.length > 0" />

          <div class="row mb-2">
            <div class="col-12 d-flex flex-row align-items-center">
              <k-inputs
                v-model="fileNameWatchlist"
                class="custom-placeholder mb-0 me-2"
                placeholder="Create new list"
                :valid="validWatchlistName"
              ></k-inputs>
              <k-button
                theme-color="primary"
                :disabled="!fileNameWatchlist"
                @click="saveWatchlist(fileNameWatchlist, false)"
                >Save New</k-button
              >
            </div>
          </div>
          <div class="row">
            <div v-if="!validWatchlistName" class="col-6 errorfont">
              Watchlist name already exists. <br />Please use a different
              watchlist name.
            </div>
            <div v-else class="col-6"></div>
            <div class="col-6 text-end">
              <k-button
                fill-mode="outline"
                @click="
                  (saveWatchlistDialog = false),
                    (fileNameFilter = null),
                    (validWatchlistName = true)
                "
                >Close</k-button
              >
            </div>
          </div>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmDeleteWatchlistDialog"
      :title="' '"
      @close="confirmDeleteWatchlistDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center">
            Are you sure you want to delete the watchlist section, ‘{{
              selectedWatchlists.toString().replaceAll(',', '’ / ‘')
            }}’?
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="confirmDeleteWatchlistDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="deleteWatchlist"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="watchlistNotMatchDialog"
      :title="' '"
      @close="watchlistNotMatchDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="text-center"><icon-warning class="text-warning" /></div>
          <div class="fw-bold text-center">{{ watchlistMessage }}</div>
          <div class="fw-bold text-center">
            The details of the following cannot be uploaded:
          </div>
        </div>
      </div>
      <div class="row mb-3" v-if="watchlistNotMatch.length > 0">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in watchlistNotMatch"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            <span class="ms-2" v-text="item"></span>
          </div>
        </div>
      </div>
      <div v-if="watchlistFooterMessage" class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center">{{ watchlistFooterMessage }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="watchlistNotMatchDialog = false"
          >Close</k-button
        >
      </div>
    </k-dialog>
    <k-dialog
      v-if="unavailSavedFilterDialog"
      :title="' '"
      @close="(isShare = false), (unavailSavedFilterDialog = false)"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div v-if="restrictAccessLoadFilter" class="col-12 pt-4">
          <div v-if="isShare" class="fw-bold text-center">
            You don't currently have permission to access an Exchange country in
            the Share Filter. The filter will be not applied.
          </div>
          <div v-else class="fw-bold text-center">
            You don't currently have permission to access an Exchange country in
            the Saved Share Filter. The filter will be not applied.
          </div>
        </div>
        <div v-if="unavailFilter.length > 0" class="col-12 pt-4">
          <div class="fw-bold text-center">
            Some data in this saved filter don't match with current dataset.
          </div>
          <div class="fw-bold text-center">The details are below:</div>
        </div>
      </div>
      <div class="row mb-3" v-if="unavailFilter.length > 0">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in unavailFilter"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            <span class="ms-2" v-text="item"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="unavailSavedFilterDialog = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="restrictAccess"
      :title="' '"
      @close="restrictAccess = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="fw-bold text-center">
            You don't currently have permission to access this
            {{ selectedExchange }} exchange.
          </div>
          <div class="fw-bold text-center">
            The filter will return to your default Exchange country.
          </div>
          <div class="fw-bold text-center">
            Click on Cancel to dismiss this message
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="restrictAccess = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="restrictAccessWatchlist"
      :title="' '"
      @close="cleanRestrictWatchlist()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">
            You don't have access to some Exchange country(s). The details are:
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in replacedExchangeWatchlist"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            &bull; <span class="ms-2" v-text="item"></span> is changed to the
            default exchange.
          </div>
          <div
            v-for="(item, idx) in blockedExchangeWatchlist"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            &bull; <span class="ms-2" v-text="item"></span> is not in your list
            of Exchange country(s).
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="cleanRestrictWatchlist()"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- Exchange Not Found -->
    <k-dialog
      v-if="exchangeNotAvailable"
      :title="' '"
      @close="clearFilter()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">No Exchange country Data Found</div>
          <div class="text-center">
            Click Cancel to dismiss this message and load default exchange
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button class="me-2" fill-mode="outline" @click="clearFilter()"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- Year Not Found -->
    <k-dialog
      v-if="yearNotAvailable"
      :title="' '"
      @close="selectLatestYearAvailable()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">No Year Data Found</div>
          <div class="text-center">
            Click Cancel to dismiss this message and load default Latest Year
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="selectLatestYearAvailable()"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- hidden input file for upload watchlist -->
    <input
      ref="watchlistFile"
      type="file"
      accept="text/csv"
      @change="uploadWatchlist"
      style="display: none"
    />

    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../../main.js';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import $ from 'jquery';
import CustomMultiSelect from '../form/k-custom-multiselect';
import { ComboBox } from '@progress/kendo-vue-dropdowns';
import {
  DEFAULT_OPEN_PANEL,
  PANEL_BAR_ITEMS,
  PANEL_BAR_KEYS
} from '@/modules/PanelBar/constants';
import { MAX_SEARCH_RECORDS } from '../tenantAdmin/constants';
import watchlistMixins from './mixins/watchlist';

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 20;

export default {
  components: {
    'k-custom-multiselect': CustomMultiSelect,
    ComboBox
  },
  mixins: [watchlistMixins],
  data() {
    return {
      loaderImage: {
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        year: false,
        rat: false
      },
      defaultExchange: null,
      filterSizeRecord: null,
      initFlag: true,
      showAdvFilter: true,
      advFilterText: 'Hide',
      searchKeyword: '',
      keyword: '',
      searchResponse: [],
      searchTotal: 0,
      isResponseOverloaded: false,
      selectedField: 'selected',
      selectedItem: {},
      selectedTicker: {
        year: null,
        tickerID: null,
        exchange_countryID: null,
        exchange_country: ''
      },
      selectedTab: 0,
      search: '',
      skip: DEFAULT_SKIP,
      take: DEFAULT_TAKE,
      pageSize: 10,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [20, 50, 100],
        previousNext: true,
        responsive: true
      },
      total: 0,
      sort: [{ field: 'final_score', dir: 'desc' }],
      riskScoreSort: 'desc',
      availableData: [],
      dataItems: [],
      columns: [
        { field: 'isin', title: 'ISIN', width: 100 },
        { field: 'name', title: 'Name', width: 150, MaxWidth: 200 },
        { field: 'domicile_country', title: 'Domicile', width: 100 },
        {
          field: 'rank_percent',
          title: 'RankPercent',
          cell: 'scoreFormat',
          width: 100
        },
        {
          field: 'rank_percent_within_sector',
          title: 'RankPerSector',
          cell: 'scoreFormat',
          width: 100
        },
        {
          field: 'rating',
          title: 'Rating',
          width: 100
        },
        {
          field: 'final_score',
          title: 'Risk',
          cell: 'scoreFormat',
          headerCell: 'yearTemplate',
          width: 110,
          headerAttributes: {
            style:
              'border-right: 1px solid #222222;border-left: 1px solid #222222;'
          }
        },
        {
          headerCell: 'actionHeaderTemplate',
          cell: 'actionTemplate',
          width: 30
        }
        //,{ field: "year", title: "Year" }
      ],
      filter: {
        order: [],
        minRiskScore: null,
        maxRiskScore: null,
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        index: 0,
        maxResult: 100,
        pageToken: null,
        year: null,
        choosenYear: null
      },
      preFilter: {},
      year: [],
      count: {
        riskScoreCount: null,
        sectorCount: null,
        industryCount: null,
        auditorCount: null,
        domicileCount: null,
        incorporationCount: null,
        exchangeCount: null,
        statusCount: null,
        oneYearChangeCount: null,
        marketCapDecileCount: null
      },
      riskScoreDefault: [0, 100],
      riskScore: [],
      oneYearChangeDefault: [-100, 100],
      oneYearChange: [],
      marketCapDecileDefault: [1, 10],
      marketCapDecile: [],
      statusExchange: null,
      sector: [],
      industry: [],
      auditor: [],
      domicile: [],
      incorporation: [],
      exchange: [],
      status: [],
      rating: [],
      fileNameFilter: null,
      fileNameWatchlist: null,
      disableSaveFilter: true,
      downloadDialog: false,
      downloadSaveDialog: false,
      selectedSavedFilters: [],
      saveFilterDialog: false,
      confirmSaveFilterDialog: false,
      confirmDeleteSaveFilterDialog: false,
      isCurrentSelection: true,
      panelBar: DEFAULT_OPEN_PANEL,
      saveWatchlistDialog: false,
      watchlist: { name: null, companies: [] },
      selectedWatchlists: [],
      confirmDeleteWatchlistDialog: false,
      rerender: true,
      unavailSavedFilterDialog: false,
      unavailFilter: [],
      restrictAccess: false,
      selectedExchange: null,
      dynamicFlag: {
        rs: false,
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        oyc: false,
        mcd: false,
        yr: false,
        rat: false
      },
      timeout: null,
      tempYear: null,
      lastSelectedYear: null,
      sliderActiveRs: false,
      sliderActiveOyc: false,
      sliderActiveMcd: false,
      restrictAccessLoadFilter: false,
      savedFilterUnmatch: false,
      isLoad: false,
      isShare: false,
      exchangeNotAvailable: false,
      yearNotAvailable: false,
      previousRating: null
    };
  },
  created() {
    this.DEFAULT_OPEN_PANEL = DEFAULT_OPEN_PANEL;
    this.PANEL_BAR_ITEMS = PANEL_BAR_ITEMS;
    this.PANEL_BAR_KEYS = PANEL_BAR_KEYS;
  },
  watch: {
    riskScore: function (newV) {
      if (
        newV[0] != this.riskScoreDefault[0] ||
        newV[1] != this.riskScoreDefault[1]
      ) {
        this.sliderActiveRs = true;
      } else {
        this.sliderActiveRs = false;
      }
      this.saveFilterParams({ ...this.filter, riskScore: newV });
    },
    oneYearChange: function (newV) {
      if (
        newV[0] != this.oneYearChangeDefault[0] ||
        newV[1] != this.oneYearChangeDefault[1]
      ) {
        this.sliderActiveOyc = true;
      } else {
        this.sliderActiveOyc = false;
      }
      this.saveFilterParams({ ...this.filter, oneYearChange: newV });
    },
    marketCapDecile: function (newV) {
      if (
        newV[0] != this.marketCapDecileDefault[0] ||
        newV[1] != this.marketCapDecileDefault[1]
      ) {
        this.sliderActiveMcd = true;
      } else {
        this.sliderActiveMcd = false;
      }
      this.saveFilterParams({ ...this.filter, marketCapDecile: newV });
    },
    'selectedTicker.tickerID': function (newV) {
      if (newV) {
        this.selectedItem =
          this.dataItems.find(
            (item) =>
              item.tickerID == this.selectedTicker.tickerID &&
              item.exchange_countryID == this.selectedTicker.exchange_countryID
          ) || this.selectedItem;
      }
    },
    'filter.exchange': function (newVal, oldVal) {
      if (
        newVal &&
        oldVal &&
        newVal.length != 0 &&
        oldVal.length != 0 &&
        this.isLoad == false
      ) {
        this.checkAvailableExchange();
      }
    },
    'filter.year': function (val) {
      if (val == null) {
        EventBus.$emit('filterNotApplied', true);
      }
    },
    'filter.rating': function (val) {
      if (val) {
        this.previousRating = val;
      }
    },
    tempYear: function (val) {
      this.saveFilterParams({ ...this.filter, year: val });
    },
    lastSelectedYear: function (val) {
      this.saveFilterParams({ ...this.filter, year: val });
    },
    skip: function (val) {
      this.saveFilterParams({ ...this.filter, pageSkip: val });
    },
    take: function (val) {
      this.saveFilterParams({ ...this.filter, pageTake: val });
    },
    sort: function (val) {
      if (this.initFlag) return;

      const filterOrders = [...(val || [])];

      if (!filterOrders.some((t) => t.field === 'final_score')) {
        const fieldOrder = {
          field: 'final_score',
          dir: this.riskScoreSort || 'desc'
        };
        filterOrders.push(fieldOrder);
      }
      this.saveFilterParams({ ...this.filter, filterOrders });
    },
    filter: {
      handler: function (val) {
        this.saveFilterParams(val);
      },
      deep: true
    }
  },
  mounted() {
    this.riskScore = this.riskScoreDefault;
    this.oneYearChange = this.oneYearChangeDefault;
    this.marketCapDecile = this.marketCapDecileDefault;

    if (this.$store.state.isShareUrl) {
      // action in search feature
      if (this.$store.state.shareTicker != 'null') {
        let sKey = decodeURI(this.$store.state.shareKeyword);
        if (sKey != 'null') {
          this.searchKeyword = sKey;
          this.doSearch();
        } else {
          this.searchKeyword = null;
        }
      }
    }

    // action in filter
    this.initFilter();

    // Event save watchlist triggered from userDashboard
    EventBus.$on('saveWatchlistFromDashboard', (final_score) => {
      this.selectedItem.final_score = final_score;
      this.showWatchlistDashboardDialog();
    });

    let vm = this;
    window.onresize = function () {
      vm.rerenderRangeSlider();
    };

    window.onload = function () {
      window.dispatchEvent(new Event('resize'));
    };

    $('th[aria-sort="none"]').each(function () {
      const headerCell = $(this);
      const link = headerCell.find('span.k-link');
      let icon = link.find('span.k-i-arrows-swap');
      link.hover(
        function () {
          if (!headerCell.hasClass('k-sorted')) {
            link.append('<span class="k-icon k-i-arrows-swap"></span>');
            icon = link.find('span.k-i-arrows-swap');
          } else {
            icon.remove();
          }
        },
        function () {
          icon.remove();
        }
      );
      link.click(function () {
        icon.remove();
      });
    });

    this.removeArrowButton();
  },
  destroyed() {
    EventBus.$off('saveWatchlistFromDashboard');
  },
  computed: {
    items() {
      return this.dataItems.map((item) => ({
        ...item,
        selected: item.tickerID === this.selectedTicker.tickerID
      }));
    }
  },
  methods: {
    saveFilterParams(value) {
      if (this.initFlag) return;
      this.$store.commit('saveFilterParams', value);
    },
    onChangeRiskScore(event) {
      let rs = event.value;
      this.riskScore = rs;
      this.filter.minRiskScore = rs[0];
      this.filter.maxRiskScore = rs[1];
      let min = this.filter.minRiskScore;
      let max = this.filter.maxRiskScore;
      let def = this.riskScoreDefault;
      this.loadFilterOptions('RS', this.removeFromFilterOrder(min, max, def));
    },
    onChangeOneYearChange(event) {
      let oyc = event.value;
      this.oneYearChange = oyc;
      this.filter.minOneYearChange = oyc[0];
      this.filter.maxOneYearChange = oyc[1];
      let min = this.filter.minOneYearChange;
      let max = this.filter.maxOneYearChange;
      let def = this.oneYearChangeDefault;
      this.loadFilterOptions('OYC', this.removeFromFilterOrder(min, max, def));
    },
    onChangeMarketCapDecile(event) {
      let mcd = event.value;
      this.marketCapDecile = mcd;
      this.filter.minMarketCapDecile = mcd[0];
      this.filter.maxMarketCapDecile = mcd[1];
      let min = this.filter.minMarketCapDecile;
      let max = this.filter.maxMarketCapDecile;
      let def = this.marketCapDecileDefault;
      this.loadFilterOptions('MCD', this.removeFromFilterOrder(min, max, def));
    },
    removeFromFilterOrder(min, max, def) {
      let removeFromOrder = false;
      if (min == def[0] && max == def[1]) {
        removeFromOrder = true;
      }
      return removeFromOrder;
    },
    removeArrowButton() {
      let year = document.getElementsByClassName('custom-year')[0];
      if (year) {
        let button = year.getElementsByTagName('button')[0];
        button.classList.add('d-none');
        year.addEventListener('click', function () {
          button.click();
        });
      }
    },
    onSelect: function (id) {
      //Open panel
      if (this.panelBar !== id) {
        this.panelBar = id;
      }

      if (this.panelBar === this.PANEL_BAR_KEYS.FILTERS) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    onClickPanelArrow: function (e, id) {
      e.stopPropagation();

      //Click on arrow of currently opened panel => Close all and open default panel
      if (this.panelBar === id) {
        this.panelBar = DEFAULT_OPEN_PANEL;
      } else {
        // Else open clicked panel
        this.panelBar = id;
      }

      if (this.panelBar === this.PANEL_BAR_KEYS.FILTERS) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    sortRisk() {
      if (this.riskScoreSort === 'desc') {
        this.riskScoreSort = null;
        this.sort = this.sort.filter((t) => t.field !== 'final_score');
        return;
      }

      this.riskScoreSort = this.riskScoreSort === 'asc' ? 'desc' : 'asc';

      (this.sort.find((t) => t.field === 'final_score') || {}).dir =
        this.riskScoreSort;

      this.applyFilter();
    },
    pageChangeHandler(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
      this.applyFilter();
    },
    viewTickerDetail(selectedTicker, selectedExchange) {
      this.filter.selectedID = selectedTicker.tickerID;
      this.$store.commit('fillShareFilter', this.filter);
      this.$store.commit('fillShareTicker', selectedTicker.tickerID);
      this.$store.commit('fillShareRegion', selectedTicker.exchange_countryID);

      let ticker = {};
      ticker.id = selectedTicker.tickerID;
      ticker.exchange = selectedTicker.exchange_countryID;
      ticker.year = selectedTicker.year;

      ticker.available = true;

      let result = this.exchange
        .map((exchange) => parseInt(exchange.exchange_countryID))
        .includes(parseInt(selectedTicker.exchange_countryID));
      if (!result) {
        ticker.available = false;
      }

      if (!selectedExchange) {
        let selectedExchange = this.exchange.find((exchange) =>
          this.filter.exchange.includes(exchange.exchange_countryID)
        );
        ticker.selectedExchange =
          selectedExchange && selectedExchange.exchange_country
            ? selectedExchange.exchange_country
            : null;
      } else {
        ticker.selectedExchange = selectedExchange;
      }
      ticker.selectedExchange = selectedExchange;
      this.selectedTicker.exchange_country = ticker.selectedExchange;
      this.$store.commit('fillShareRegionName', ticker.selectedExchange);
      EventBus.$emit('ticker', ticker);
    },
    doSearch() {
      if (this.searchKeyword.length > 0) {
        axios
          .post('/dashboard/search', {
            keywords: this.searchKeyword.toUpperCase(),
            maxResult: MAX_SEARCH_RECORDS,
            index: 0,
            pageToken: ''
          })
          .then((response) => {
            this.searchResponse = [];
            this.isResponseOverloaded = false;
            this.keyword = this.searchKeyword;
            this.searchResponse = [...response.data.data];
            this.searchTotal = this.searchResponse.length;

            if (response.data.total > MAX_SEARCH_RECORDS) {
              this.searchResponse = [];
              this.searchTotal = 0;
              this.isResponseOverloaded = true;
            }

            this.$store.commit('fillShareKeyword', this.keyword);
          });
      } else {
        this.searchResponse = [];
        this.searchTotal = 0;
        this.keyword = '';
      }
    },
    sortChangeHandler(e) {
      this.sort = e.sort;

      if (e.event.field === 'final_score') {
        this.sortRisk();
        return;
      }

      this.applyFilter();
    },
    onResultClick(item) {
      this.selectedTicker.tickerID = item.tickerID;
      this.selectedTicker.exchange_countryID = item.exchange_countryID;
      // Don't set the year in selectedTicker, use last available year for stock from search result
      this.selectedTicker.year = null;
      this.selectedItem = item;
      this.viewTickerDetail(this.selectedTicker, null);
    },
    onRowClick(item) {
      this.selectedTicker.tickerID = item.dataItem.tickerID;
      this.selectedTicker.exchange_countryID = item.dataItem.exchange_countryID;
      this.selectedTicker.year = this.filter.year;
      this.viewTickerDetail(this.selectedTicker, null);
    },

    onSelectTab(e) {
      this.selectedTab = e;
      if (this.selectedTab == 0) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    advFilter() {
      if (this.advFilterText == 'Show') {
        this.advFilterText = 'Hide';
        this.showAdvFilter = true;
        document
          .getElementById('gridFilter')
          .classList.remove('grid-filter-max');
        document.getElementById('gridFilter').classList.add('grid-filter-min');
        window.dispatchEvent(new Event('resize'));
      } else if (this.advFilterText == 'Hide') {
        this.advFilterText = 'Show';
        this.showAdvFilter = false;
        document
          .getElementById('gridFilter')
          .classList.remove('grid-filter-min');
        document.getElementById('gridFilter').classList.add('grid-filter-max');
        window.dispatchEvent(new Event('resize'));
      }
    },

    // ---------------
    checkAvailableExchange() {
      let result = false;

      for (const exchange of this.exchange) {
        if (
          exchange.exchange_countryID &&
          this.filter.exchange
            .map((exchange) => parseInt(exchange))
            .includes(exchange.exchange_countryID)
        ) {
          result = true;
        }
      }
      if (result) {
        this.loadFilterOptions('EXC');
      } else {
        this.exchangeNotAvailable = true;
      }
    },
    checkAvailableYear() {
      this.skip = 0;
      this.applyFilter();
    },
    selectLatestYearAvailable() {
      this.yearNotAvailable = false;
      this.tempYear = this.year[0];
    },
    applyPersistedFilter() {
      const {
        year,
        riskScore,
        oneYearChange,
        marketCapDecile,
        pageSkip,
        pageTake,
        lastSelectedYear,
        filterOrders
      } = this.$store.state.filterParams || {};

      this.tempYear = year || this.tempYear;
      this.filter = {
        ...this.filter,
        ...this.$store.state.filterParams
      };
      this.riskScore = riskScore || this.riskScore;
      this.oneYearChange = oneYearChange || this.oneYearChange;
      this.marketCapDecile = marketCapDecile || this.marketCapDecile;
      this.skip = pageSkip || this.skip;
      this.take = pageTake || this.take;
      this.lastSelectedYear = lastSelectedYear || this.lastSelectedYear;

      if (!(filterOrders || []).some((t) => t.field === 'final_score')) {
        this.riskScoreSort =
          filterOrders?.find(({ field }) => field === 'final_score')?.dir ||
          this.riskScoreSort;
        const fieldOrder = {
          field: 'final_score',
          dir: this.riskScoreSort || 'desc'
        };
        if (this.riskScoreSort) {
          this.sort.push(fieldOrder);
        }
      }
    },
    applyFilter() {
      // conversion string to int array for sector and industry
      this.selectedExchange = '';
      this.filter.year = this.tempYear;
      if (this.filter.sectorId.length > 0)
        this.filter.sectorId = this.filter.sectorId.map((i) => Number(i));
      if (this.filter.industryGroupId.length > 0)
        this.filter.industryGroupId = this.filter.industryGroupId.map((i) =>
          Number(i)
        );

      if (!this.initFlag) {
        if (!this.filter.exchange || this.filter.exchange.length === 0) {
          this.statusExchange = 'Exchange country is required';
          return;
        } else {
          let selectedExchange = this.exchange.find((exchange) =>
            this.filter.exchange.includes(exchange.exchange_countryID)
          );
          this.selectedExchange = selectedExchange.exchange_country;
          this.statusExchange = null;
        }

        if (
          this.riskScore[0] !== this.riskScoreDefault[0] ||
          this.riskScore[1] !== this.riskScoreDefault[1]
        ) {
          this.filter.minRiskScore = this.riskScore[0];
          this.filter.maxRiskScore = this.riskScore[1];
        } else {
          this.filter.minRiskScore = null;
          this.filter.maxRiskScore = null;
        }

        if (
          this.oneYearChange[0] !== this.oneYearChangeDefault[0] ||
          this.oneYearChange[1] !== this.oneYearChangeDefault[1]
        ) {
          this.filter.minOneYearChange = this.oneYearChange[0];
          this.filter.maxOneYearChange = this.oneYearChange[1];
        } else {
          this.filter.minOneYearChange = null;
          this.filter.maxOneYearChange = null;
        }

        if (
          this.marketCapDecile[0] !== this.marketCapDecileDefault[0] ||
          this.marketCapDecile[1] !== this.marketCapDecileDefault[1]
        ) {
          this.filter.minMarketCapDecile = this.marketCapDecile[0];
          this.filter.maxMarketCapDecile = this.marketCapDecile[1];
        } else {
          this.filter.minMarketCapDecile = null;
          this.filter.maxMarketCapDecile = null;
        }
      }

      this.filter.pageSkip = this.skip;
      this.filter.pageTake = this.take;
      const filterOrders = [...(this.sort || [])];

      if (!filterOrders.some((t) => t.field === 'final_score')) {
        const fieldOrder = {
          field: 'final_score',
          dir: this.riskScoreSort || 'desc'
        };
        filterOrders.push(fieldOrder);

        if (this.riskScoreSort) {
          this.sort.push(fieldOrder);
        }
      }

      this.filter.filterOrders = filterOrders;

      const tempFilter = JSON.parse(JSON.stringify(this.filter));

      axios({
        method: 'POST',
        url: '/dashboard/filter',
        data:
          tempFilter.exchange && tempFilter.exchange.length > 0
            ? JSON.stringify(tempFilter)
            : JSON.stringify({
                ...tempFilter,
                exchange: this.exchange.map(
                  ({ exchange_countryID }) => exchange_countryID
                )
              }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.dataItems = response.data.data;
          this.total = response.data.total;

          if (response.data.restrictAccess) {
            this.restrictAccess = true;
            this.fetchExchange(this.filter);
          }
        })
        .then(() => {
          this.$nextTick(() => {
            this.disableSaveFilter = this.dataItems.length <= 0;
            this.filter.riskScore = this.$root.clone(this.riskScore);
            this.filter.oneYearChange = this.$root.clone(this.oneYearChange);
            this.filter.marketCapDecile = this.$root.clone(
              this.marketCapDecile
            );
            // Select Default year by User
            this.filter.lastSelectedYear = this.lastSelectedYear;
            this.$store.commit('fillShareFilter', this.filter);

            if (this.initFlag && this.dataItems.length > 0) {
              this.initFlag = false;

              if (!this.$store.state.isShareUrl) {
                this.selectedTicker.tickerID = this.dataItems[0].tickerID;
                this.selectedTicker.exchange_countryID =
                  this.dataItems[0].exchange_countryID;
                this.selectedTicker.exchange_country =
                  this.dataItems[0].exchange_country;
                this.selectedTicker.year = this.filter.year || this.tempYear;
                this.viewTickerDetail(
                  this.selectedTicker,
                  this.selectedTicker.exchange_country
                );
              }
            }
          });
        });

      if (!this.initFlag) {
        axios.post('/dashboard/filterCount', this.filter).then((response) => {
          this.count = response.data;
        });
      }

      EventBus.$emit('filterNotApplied', false);
    },
    // ---------------
    showSaveFilterDialog() {
      if (!this.filter.exchange || this.filter.exchange.length === 0) {
        this.statusExchange = 'Exchange country is required';
      } else {
        this.saveFilterDialog = true;
      }
    },
    saveFilter(actionType) {
      this.filter.riskScore = this.$root.clone(this.riskScore);
      this.filter.oneYearChange = this.$root.clone(this.oneYearChange);
      this.filter.marketCapDecile = this.$root.clone(this.marketCapDecile);
      if (actionType == 'add') {
        let index = this.$store.state.filters.findIndex(
          (x) => x.name == this.fileNameFilter
        );
        if (index >= 0) {
          this.confirmSaveFilterDialog = true;
        } else {
          this.confirmSaveFilter(actionType);
        }
      } else {
        this.confirmSaveFilter(actionType);
      }
      this.saveFilterDialog = false;
    },
    confirmSaveFilter(actionType) {
      // handling for reference to previous saved filter data
      let saveFilter = this.$root.clone(this.filter);

      saveFilter.year = this.tempYear;
      // save selected year by user
      saveFilter.lastSelectedYear = this.lastSelectedYear || null;
      if (
        this.fileNameFilter &&
        (actionType == 'add' || actionType == 'replace')
      ) {
        saveFilter.name = this.$root.clone(this.fileNameFilter);
      }
      if (this.isCurrentSelection) {
        saveFilter.selectedID = this.selectedTicker.tickerID;
        saveFilter.selectedRegionID = this.selectedTicker.exchange_countryID;
        saveFilter.selectedExchange = this.selectedTicker.exchange_country;
        saveFilter.choosenYear = this.$store.state.shareYear;
      } else {
        saveFilter.selectedID = null;
        saveFilter.selectedRegionID = null;
        saveFilter.selectedExchange = null;
        saveFilter.choosenYear = null;
      }
      this.$store.commit('saveFilter', saveFilter);
      this.fileNameFilter = null;
      this.confirmSaveFilterDialog = false;
      // set new saved filter to current filter
      this.filter = this.$root.clone(saveFilter);
      this.$store.commit('saveFilterParams', this.filter);
    },
    // ---------------
    async loadSavedFilter(selectedFilter) {
      // deep copy
      let load = JSON.parse(JSON.stringify(selectedFilter));
      let filtered = JSON.parse(JSON.stringify(selectedFilter));
      if (!filtered.order) {
        return;
      }
      this.resetDynamicFlag();
      this.restrictAccessLoadFilter = false;
      this.unavailFilter = [];
      let unavailData = [];
      let counter = 0;
      let filtertmp = {
        order: [],
        minRiskScore: null,
        maxRiskScore: null,
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        year: null,
        riskScore: [],
        marketCapDecile: [],
        oneYearChange: []
      };

      load.auditorId = load.auditorId ?? [];
      load.domicile = load.domicile ?? [];
      load.exchange = load.exchange ?? [];
      load.incorporation = load.incorporation ?? [];
      load.industryGroupId = load.industryGroupId ?? [];
      load.sectorId = load.sectorId ?? [];
      load.status = load.status ?? [];
      load.rating = load.rating ?? [];

      //add year order so year will fetch at the end
      filtered.order.push('YEAR');

      for (const order of filtered.order) {
        if (this.restrictAccessLoadFilter) {
          continue;
        }

        if (order == 'RS') {
          this.clearDataFilter();
          this.dynamicFlag.rs = true;
          filtertmp.order.push('RS');

          await this.fetchRiskScore(filtertmp);

          if (this.riskScoreDefault[0] > load.riskScore[0]) {
            this.unavailFilter.push(
              `Min Risk Score : ${load.riskScore[0]} will be changed to ${this.riskScoreDefault[0]}`
            );
            filtered.riskScore[0] = this.riskScoreDefault[0];
            filtered.minRiskScore = this.riskScoreDefault[0];
          }
          if (this.riskScoreDefault[1] < load.riskScore[1]) {
            this.unavailFilter.push(
              `Max Risk Score : ${load.riskScore[1]} will be changed to ${this.riskScoreDefault[1]}`
            );
            filtered.riskScore[1] = this.riskScoreDefault[1];
            filtered.maxRiskScore = this.riskScoreDefault[1];
          }

          filtertmp.minRiskScore = this.$root.clone(filtered.minRiskScore);
          filtertmp.maxRiskScore = this.$root.clone(filtered.maxRiskScore);
          filtertmp.riskScore = this.$root.clone(filtered.riskScore);
        } else if (order == 'SEC' && filtered.sectorId) {
          this.clearDataFilter();
          this.dynamicFlag.sec = true;
          filtertmp.order.push('SEC');

          const response = await this.fetchSector(filtertmp);

          unavailData = [];
          counter = 0;
          for (const sectorId of load.sectorId) {
            if (!response.data.some((e) => e.sectorID == sectorId)) {
              unavailData[counter] = sectorId;
              counter++;
              filtered.sectorId = filtered.sectorId.filter(
                (data) => data != sectorId
              );
            }
          }
          if (unavailData.length > 0) {
            this.unavailFilter.push(
              `Sector ID ${unavailData} will not be included in the filter`
            );
          }
          filtertmp.sectorId = this.$root.clone(filtered.sectorId);
        } else if (order == 'IG' && filtered.industryGroupId) {
          this.clearDataFilter();
          this.dynamicFlag.ig = true;
          filtertmp.order.push('IG');

          const response = await this.fetchIndustry(filtertmp);

          unavailData = [];
          counter = 0;
          for (const industryGroupId of load.industryGroupId) {
            if (
              !response.data.some((e) => e.industry_groupID == industryGroupId)
            ) {
              unavailData[counter] = industryGroupId;
              counter++;
              filtered.industryGroupId = filtered.industryGroupId.filter(
                (data) => data != industryGroupId
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Industry Group ID ${unavailData} will not be included in the filter`
            );

          filtertmp.industryGroupId = this.$root.clone(
            filtered.industryGroupId
          );
        } else if (order == 'AUD' && filtered.auditorId) {
          this.clearDataFilter();
          this.dynamicFlag.aud = true;
          filtertmp.order.push('AUD');

          const response = await this.fetchAuditor(filtertmp);

          unavailData = [];
          counter = 0;
          for (const auditorId of load.auditorId) {
            if (!response.data.some((e) => e.auditorID == auditorId)) {
              unavailData[counter] = auditorId;
              counter++;
              filtered.auditorId = filtered.auditorId.filter(
                (data) => data != auditorId
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Auditor ID ${unavailData} will not be included in the filter`
            );
          filtertmp.auditorId = this.$root.clone(filtered.auditorId);
        } else if (order == 'DOM' && filtered.domicile) {
          this.clearDataFilter();
          this.dynamicFlag.dom = true;
          filtertmp.order.push('DOM');

          const response = await this.fetchDomicile(filtertmp);

          unavailData = [];
          counter = 0;
          for (const domicile of load.domicile) {
            if (!response.data.some((e) => e.domicile_country == domicile)) {
              unavailData[counter] = domicile;
              counter++;
              filtered.domicile = filtered.domicile.filter(
                (data) => data != domicile
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Domicile ID ${unavailData} will not be included in the filter`
            );
          filtertmp.domicile = this.$root.clone(filtered.domicile);
        } else if (order == 'EXC' && filtered.exchange) {
          this.clearDataFilter();
          this.dynamicFlag.exc = true;
          filtertmp.order.push('EXC');

          const response = await this.fetchExchange(filtertmp);

          unavailData = [];
          counter = 0;

          for (let x = 0; x < load.exchange.length; x++) {
            if (
              !response.data.some(
                (e) => e.exchange_countryID == load.exchange[x]
              )
            ) {
              this.restrictAccessLoadFilter = true;
              unavailData[counter] = load.exchange[x];
              counter++;
              filtered.exchange = filtered.exchange.filter(
                (data) => data != load.exchange[x]
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Exchange ID ${unavailData} will not be included in the filter`
            );
          filtertmp.exchange = this.$root.clone(filtered.exchange);
        } else if (order == 'INC' && filtered.incorporation) {
          this.clearDataFilter();
          this.dynamicFlag.inc = true;
          filtertmp.order.push('INC');

          const response = await this.fetchIncorporation(filtertmp);

          unavailData = [];
          counter = 0;
          for (const incorporation of load.incorporation) {
            if (
              !response.data.some(
                (e) => e.incorporation_country == incorporation
              )
            ) {
              unavailData[counter] = incorporation;
              counter++;
              filtered.incorporation = filtered.incorporation.filter(
                (data) => data != incorporation
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Incorporation ID ${unavailData} will not be included in the filter`
            );
          filtertmp.incorporation = this.$root.clone(filtered.incorporation);
        } else if (order == 'STA' && filtered.status) {
          this.clearDataFilter();
          this.dynamicFlag.sta = true;
          filtertmp.order.push('STA');

          const response = await this.fetchStatus(filtertmp);

          unavailData = [];
          counter = 0;
          for (const status of load.status) {
            if (!response.data.some((e) => e.trading_status == status)) {
              unavailData[counter] = status;
              counter++;
              filtered.status = filtered.status.filter(
                (data) => data != status
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Status ${unavailData} will not be included in the filter`
            );
        } else if (order == 'OYC') {
          this.clearDataFilter();
          this.dynamicFlag.oyc = true;
          filtertmp.order.push('OYC');

          await this.fetchOneYearChange(filtertmp);

          if (this.oneYearChangeDefault[0] > load.oneYearChange[0]) {
            this.unavailFilter.push(
              `Min 1 year change : ${load.oneYearChange[0]} will be changed to ${this.oneYearChangeDefault[0]}`
            );
            filtered.oneYearChange[0] = this.oneYearChangeDefault[0];
            filtered.minOneYearChange = this.oneYearChangeDefault[0];
          }
          if (this.oneYearChangeDefault[1] < load.oneYearChange[1]) {
            this.unavailFilter.push(
              `Max 1 year change : ${load.oneYearChange[1]} will be changed to ${this.oneYearChangeDefault[1]}`
            );
            filtered.oneYearChange[1] = this.oneYearChangeDefault[1];
            filtered.maxOneYearChange = this.oneYearChangeDefault[1];
          }
          filtertmp.minOneYearChange = this.$root.clone(
            filtered.minOneYearChange
          );
          filtertmp.maxOneYearChange = this.$root.clone(
            filtered.maxOneYearChange
          );
          filtertmp.oneYearChange = this.$root.clone(filtered.oneYearChange);
        } else if (order == 'MCD') {
          this.clearDataFilter();
          this.dynamicFlag.mcd = true;
          filtertmp.order.push('MCD');

          await this.fetchMarketCapDecile(filtertmp);

          if (this.marketCapDecileDefault[0] > load.marketCapDecile[0]) {
            this.unavailFilter.push(
              `Min Market Cap Decile : ${load.marketCapDecile[0]} will be changed to ${this.marketCapDecileDefault[0]}`
            );
            filtered.marketCapDecile[0] = this.marketCapDecileDefault[0];
            filtered.minMarketCapDecile = this.marketCapDecileDefault[0];
          }
          if (this.marketCapDecileDefault[1] < load.marketCapDecile[1]) {
            this.unavailFilter.push(
              `Max Market Cap Decile : ${load.marketCapDecile[1]} will be changed to ${this.marketCapDecileDefault[1]}`
            );
            filtered.marketCapDecile[1] = this.marketCapDecileDefault[1];
            filtered.maxMarketCapDecile = this.marketCapDecileDefault[1];
          }
          filtertmp.minMarketCapDecile = this.$root.clone(
            filtered.minMarketCapDecile
          );
          filtertmp.maxMarketCapDecile = this.$root.clone(
            filtered.maxMarketCapDecile
          );
          filtertmp.marketCapDecile = this.$root.clone(
            filtered.marketCapDecile
          );
        } else if (order == 'YEAR' && filtered.year) {
          this.year = [];
          const response = await this.fetchYear(filtertmp);

          if (!response.data.includes(load.year)) {
            this.unavailFilter.push(
              `Year : ${load.year} will be changed to ${this.year[0]}`
            );
            filtered.year = this.year[0];
          }
          this.tempYear = this.$root.clone(filtered.year);
        } else if (order == 'RAT' && filtered.rating) {
          this.clearDataFilter();
          this.dynamicFlag.rat = true;
          filtertmp.order.push('RAT');

          const response = await this.fetchRating(filtertmp);

          unavailData = [];
          counter = 0;
          for (const rating of load.rating) {
            if (
              !response.data
                .map(({ rating }) => rating)
                .some((e) => e == rating)
            ) {
              unavailData[counter] = rating;
              counter++;
              filtered.rating = filtered.rating.filter(
                (data) => data != rating
              );
            }
          }
          if (unavailData.length > 0)
            this.unavailFilter.push(
              `Rating ${unavailData} will not be included in the filter`
            );
          filtertmp.rating = this.$root.clone(filtered.rating);
        }
      }
      if (this.unavailFilter.length > 0 || this.restrictAccessLoadFilter) {
        this.unavailSavedFilterDialog = true;
      }
      // Apply selected year
      this.lastSelectedYear = filtered.lastSelectedYear;
      this.tempYear = filtered.year;
      this.loadFilter(filtered);

      this.$nextTick(() => {
        if (!this.restrictAccessLoadFilter) {
          this.applyFilter();
        } else {
          EventBus.$emit('filterNotApplied', true);
          this.dataItems = [];
          this.count = {};
        }
        window.dispatchEvent(new Event('resize'));
      });
    },
    // ---------------

    loadFilter(selectedFilter) {
      this.isLoad = true;
      this.filter = this.$root.clone(selectedFilter);
      this.$store.commit('saveFilterParams', this.filter);
      this.$nextTick(() => {
        this.isLoad = false;
        this.riskScore = this.$root.clone(selectedFilter.riskScore);
        this.oneYearChange = this.$root.clone(selectedFilter.oneYearChange);
        this.marketCapDecile = this.$root.clone(selectedFilter.marketCapDecile);
        if (selectedFilter.selectedID) {
          this.selectedTicker.tickerID = selectedFilter.selectedID;
          this.selectedTicker.exchange_countryID =
            selectedFilter.selectedRegionID;
          this.selectedTicker.selectedExchange =
            selectedFilter.selectedExchange;
          this.selectedTicker.year =
            selectedFilter.choosenYear == null
              ? selectedFilter.year
              : selectedFilter.choosenYear;
          this.viewTickerDetail(
            this.selectedTicker,
            this.selectedTicker.selectedExchange
          );
        }
        this.selectedTab = 0;
      });
    },
    deleteSavedFilter() {
      for (let selectedSavedFilter of this.selectedSavedFilters) {
        this.$store.commit('deleteFilter', selectedSavedFilter);
        if (this.filter.name == selectedSavedFilter)
          this.filter.name = undefined;
      }
      this.selectedSavedFilters = [];
      this.confirmDeleteSaveFilterDialog = false;
    },
    // ---------------
    async initFilter() {
      if (this.$store.state.isShareUrl) {
        this.isShare = true;
        this.initFlag = false;
        this.loadSavedFilter(this.$store.state.shareFilter);
      } else {
        await this.fetchExchange(this.filter);
        await this.fetchYear(this.filter);
        await this.fetchOthersFilterOptions();
        this.applyPersistedFilter();
        this.applyFilter();
      }
    },
    loadFilterOptions(dropDownName, isEmpty) {
      this.$root.loader.dynamicFilter = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.deBounceLoadFilterOptions(dropDownName, isEmpty);
      }, 500);
    },
    async deBounceLoadFilterOptions(dropDownName, isEmpty) {
      let available = { is: false, index: null, is_empty: false };
      this.filter.order.forEach((order, index) => {
        if (order == dropDownName) {
          available.is = true;
          available.index = index;
          available.is_empty = isEmpty;
        }
      });
      if (available.is_empty) {
        this.filter.order.splice(available.index, 1);
      }
      await this.checkAvailableOrder(available.is, dropDownName);
    },
    async checkAvailableOrder(available, dropDownName) {
      if (!available) {
        this.filter.order.push(dropDownName);
      }
      await this.resetDynamicFlag();
      this.filter.order.forEach((order) => {
        if (order == 'RS') {
          this.dynamicFlag.rs = true;
        } else if (order == 'SEC') {
          this.dynamicFlag.sec = true;
        } else if (order == 'IG') {
          this.dynamicFlag.ig = true;
        } else if (order == 'AUD') {
          this.dynamicFlag.aud = true;
        } else if (order == 'DOM') {
          this.dynamicFlag.dom = true;
        } else if (order == 'EXC') {
          this.dynamicFlag.exc = true;
        } else if (order == 'INC') {
          this.dynamicFlag.inc = true;
        } else if (order == 'STA') {
          this.dynamicFlag.sta = true;
        } else if (order == 'OYC') {
          this.dynamicFlag.oyc = true;
        } else if (order == 'MCD') {
          this.dynamicFlag.mcd = true;
        } else if (order == 'RAT') {
          this.dynamicFlag.rat = true;
        }
      });
      await this.loadUnsellectFilterOptions();
    },
    loadUnsellectFilterOptions() {
      this.filter.year = null;
      this.clearDataFilter();
      this.$nextTick(() => {
        if (!this.dynamicFlag.rs) {
          this.fetchRiskScore(this.filter);
        }
        if (!this.dynamicFlag.sec) {
          this.fetchSector(this.filter);
        }
        if (!this.dynamicFlag.ig) {
          this.fetchIndustry(this.filter);
        }
        if (!this.dynamicFlag.aud) {
          this.fetchAuditor(this.filter);
        }
        if (!this.dynamicFlag.dom) {
          this.fetchDomicile(this.filter);
        }
        if (!this.dynamicFlag.exc) {
          this.fetchExchange(this.filter);
        }
        if (!this.dynamicFlag.inc) {
          this.fetchIncorporation(this.filter);
        }
        if (!this.dynamicFlag.sta) {
          this.fetchStatus(this.filter);
        }
        if (!this.dynamicFlag.oyc) {
          this.fetchOneYearChange(this.filter);
        }
        if (!this.dynamicFlag.mcd) {
          this.fetchMarketCapDecile(this.filter);
        }
        if (!this.dynamicFlag.yr) {
          this.fetchYear(this.filter);
        }
        if (!this.dynamicFlag.rat) {
          this.fetchRating(this.filter);
        }
      });
    },
    async fetchOthersFilterOptions() {
      await Promise.all([
        this.fetchRiskScore(this.filter),
        this.fetchSector(this.filter),
        this.fetchIndustry(this.filter),
        this.fetchAuditor(this.filter),
        this.fetchDomicile(this.filter),
        this.fetchExchange(this.filter),
        this.fetchIncorporation(this.filter),
        this.fetchStatus(this.filter),
        this.fetchOneYearChange(this.filter),
        this.fetchMarketCapDecile(this.filter),
        this.fetchRating(this.filter)
      ]);
    },
    saveSelectedYear() {
      this.lastSelectedYear = this.tempYear;
    },
    async fetchYear(filter) {
      this.loaderImage.year = true;
      const responseYear = await axios.post('/dashboard/year', filter);
      this.loaderImage.year = false;
      this.year = responseYear.data;
      // check for available last selected year on new year value
      if (
        this.lastSelectedYear != null &&
        this.year.includes(this.lastSelectedYear)
      ) {
        this.tempYear = this.lastSelectedYear;
      }
      return responseYear;
    },
    async fetchRiskScore(filter) {
      const response = await axios.post('/dashboard/riskScore', filter);
      this.riskScoreDefault = [
        parseFloat(Number(response.data[0].min).toFixed(2)),
        parseFloat(Number(response.data[0].max).toFixed(2))
      ];
      this.riskScore = this.$root.clone(this.riskScoreDefault);
      return response;
    },
    async fetchSector(filter) {
      this.loaderImage.sec = true;
      const response = await axios.post('/dashboard/sector', filter);
      this.loaderImage.sec = false;
      this.sector = response.data;
      return response;
    },
    async fetchIndustry(filter) {
      this.loaderImage.ig = true;
      const response = await axios.post('/dashboard/industry', filter);
      this.loaderImage.ig = false;
      this.industry = response.data;
      return response;
    },
    async fetchAuditor(filter) {
      this.loaderImage.aud = true;
      const response = await axios.post('/dashboard/auditor', filter);
      this.loaderImage.aud = false;
      this.auditor = response.data;
      return response;
    },
    async fetchDomicile(filter) {
      this.loaderImage.dom = true;
      const response = await axios.post('/dashboard/domicile', filter);
      this.loaderImage.dom = false;
      this.domicile = response.data;
      return response;
    },
    async fetchExchange(filter) {
      this.loaderImage.exc = true;
      const response = await axios.post('/dashboard/exchange', filter);
      this.loaderImage.exc = false;
      this.exchange = response.data.sort((x, y) =>
        x.exchange_country.localeCompare(y.exchange_country)
      );

      this.filter.order = [];
      this.filter.order.push('EXC');
      if (this.exchange.length > 0) {
        this.filter.exchange = this.exchange.map(
          ({ exchange_countryID }) => exchange_countryID
        );
      }

      return response;
    },
    async fetchIncorporation(filter) {
      this.loaderImage.inc = true;
      const response = await axios.post('/dashboard/incorporation', filter);
      this.loaderImage.inc = false;
      this.incorporation = response.data;
      return response;
    },
    async fetchStatus(filter) {
      this.loaderImage.sta = true;
      const response = await axios.post('/dashboard/status', filter);
      this.loaderImage.sta = false;
      this.status = response.data;
      return response;
    },
    async fetchOneYearChange(filter) {
      const responseOYC = await axios.post('/dashboard/oneYearChange', filter);
      this.oneYearChangeDefault = [
        Math.round(Number(responseOYC.data[0].min)),
        Math.round(Number(responseOYC.data[0].max))
      ];
      this.oneYearChange = this.$root.clone(this.oneYearChangeDefault);
      return responseOYC;
    },
    async fetchMarketCapDecile(filter) {
      const responseMCD = await axios.post('/dashboard/marketCD', filter);
      this.marketCapDecileDefault = [
        Number(responseMCD.data[0].min),
        Number(responseMCD.data[0].max)
      ];
      this.marketCapDecile = this.$root.clone(this.marketCapDecileDefault);
      return responseMCD;
    },
    async fetchRating(filter) {
      this.loaderImage.rat = true;
      const response = await axios.post('/dashboard/rating', filter);
      this.loaderImage.rat = false;
      this.rating = response.data.sort((x, y) => {
        const grades = [
          'A+',
          'A',
          'A-',
          'B+',
          'B',
          'B-',
          'C+',
          'C',
          'C-',
          'D+',
          'D',
          'D-',
          'E',
          'F'
        ];
        const xIndex = grades.indexOf(x.rating);
        const yIndex = grades.indexOf(y.rating);
        return xIndex - yIndex;
      });
      return response;
    },
    // ---------------
    clearFilter() {
      this.filter = {
        order: ['EXC'],
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        index: 0,
        maxResult: 100,
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        minRiskScore: null,
        maxRiskScore: null,
        pageToken: null,
        year: this.$root.clone(this.filter.year)
      };
      this.$store.commit('saveFilterParams', this.filter);
      this.count = {
        riskScoreCount: null,
        sectorCount: null,
        industryCount: null,
        auditorCount: null,
        domicileCount: null,
        incorporationCount: null,
        exchangeCount: null,
        statusCount: null,
        oneYearChangeCount: null,
        marketCapDecileCount: null
      };
      this.lastSelectedYear = null;
      this.exchangeNotAvailable = false;
      this.filter.exchange = [];
      this.$nextTick(() => {
        this.filter.exchange = this.exchange.map(
          ({ exchange_countryID }) => exchange_countryID
        );
      });
      this.tempYear = this.year[0];
    },
    resetDynamicFlag() {
      this.dynamicFlag = {
        rs: false,
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        oyc: false,
        mcd: false,
        yr: false,
        rat: false
      };
    },
    clearDataFilter() {
      if (!this.dynamicFlag.rs) {
        this.filter.maxRiskScore = null;
        this.filter.minRiskScore = null;
      }
      if (!this.dynamicFlag.sec) {
        this.filter.sectorId = [];
      }
      if (!this.dynamicFlag.ig) {
        this.filter.industryGroupId = [];
      }
      if (!this.dynamicFlag.aud) {
        this.filter.auditorId = [];
      }
      if (!this.dynamicFlag.dom) {
        this.filter.domicile = [];
      }
      if (!this.dynamicFlag.inc) {
        this.filter.incorporation = [];
      }
      if (!this.dynamicFlag.sta) {
        this.filter.status = [];
      }
      if (!this.dynamicFlag.oyc) {
        this.filter.maxOneYearChange = null;
        this.filter.minOneYearChange = null;
      }
      if (!this.dynamicFlag.mcd) {
        this.filter.maxMarketCapDecile = null;
        this.filter.minMarketCapDecile = null;
      }
      if (!this.dynamicFlag.rat) {
        this.filter.rating = [];
      }
    },
    async clearAllFilter() {
      this.$root.loader.dynamicFilter = false;
      await this.clearFilter();
      this.filter.exchange = [];
      this.$nextTick(() => {
        this.filter.exchange = this.exchange.map(
          ({ exchange_countryID }) => exchange_countryID
        );
      });
    },
    formatedNumber(value) {
      if (!value && value !== 0) return 0;

      if (value < 10 && value >= 0) {
        value = '0' + value;
      }
      return value;
    },
    activeSliderBorder(id) {
      let doc = document.getElementById(id);
      if (doc != null) doc.classList.add('active-slider');
    },
    inactiveSliderBorder(id) {
      let doc = document.getElementById(id);
      if (doc != null) doc.classList.remove('active-slider');
    },
    downloadConfirm() {
      this.downloadDialog = false;
      this.downloadSaveDialog = true;
    },
    downloadResult() {
      if (this.fileNameFilter) {
        const dataURI =
          'data:text/plain;base64,' +
          encodeBase64(this.convertToCSV(this.dataItems));
        saveAs(dataURI, this.fileNameFilter + '.csv');
        this.downloadSaveDialog = false;
        this.fileNameFilter = null;
      }
    },
    convertToCSV(jsonData) {
      let csvStr = 'ISIN,Name,Domicile,Risk\r\n';
      jsonData = jsonData.slice(0, this.filterSizeRecord);
      for (const item of jsonData) {
        csvStr += `${item.isin},${this.commaEscape(item.name)},${
          item.domicile_country
        },${Math.round(item.final_score * 100)}%\r\n`;
      }
      return csvStr;
    },
    commaEscape(str) {
      if (str.includes(',')) {
        str = '"' + str + '"';
      }
      return str;
    },
    downloadFilterResult() {
      axios
        .post('/setting/filter')
        .then((response) => {
          this.filterSizeRecord = parseInt(response.data.value);
        })
        .then(
          this.$nextTick(() => {
            this.downloadDialog = true;
          })
        );
    },
    rerenderRangeSlider() {
      let riskScoreTemp = this.$root.clone(this.riskScore);
      let oneYearChangeTemp = this.$root.clone(this.oneYearChange);
      let marketCapDecileTemp = this.$root.clone(this.marketCapDecile);
      this.riskScore = this.$root.clone(this.riskScoreDefault);
      this.oneYearChange = this.$root.clone(this.oneYearChangeDefault);
      this.marketCapDecile = this.$root.clone(this.marketCapDecileDefault);
      this.rerender = false;
      this.$nextTick(() => {
        this.rerender = true;
        this.$nextTick(() => {
          this.riskScore = riskScoreTemp;
          this.oneYearChange = oneYearChangeTemp;
          this.marketCapDecile = marketCapDecileTemp;
        });
      });
    },
    emptyString(value) {
      return value || '-';
    }
  }
};
</script>

<style lang="scss" scoped>
.warning {
  background: #b1323a;
  color: white;
  padding: 5px 20px;
  border-radius: 0.188rem;

  .warning-icon {
    margin-right: 8px;
  }
}
</style>
