<template>
  <form-container class="order-form-container" :title="formTitle">
    <template #header-icon>
      <icon-order-details class="header-icon-order" />
    </template>

    <seats-form class="mb-4" :isModifying="false" />

    <div v-if="isSubscribed" class="modify-btn-container">
      <k-button
        v-if="!isCancelingSubscription"
        class="modify-btn"
        theme-color="primary"
        @click="isShowingModalCancelSubscription = true"
      >
        Cancel Subscription
      </k-button>

      <k-button
        v-else
        class="modify-btn"
        theme-color="primary"
        @click="isShowingModalResumeSubscription = true"
      >
        Resume Subscription
      </k-button>
    </div>

    <regions-form :isModifying="false" />

    <subscription-modal
      :isModalShowing="isShowingModalResumeSubscription"
      @close="isShowingModalResumeSubscription = false"
    >
      <template #description>
        Are you sure you want to resume your subscription?
      </template>
      <template #action>
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="isShowingModalResumeSubscription = false"
        >
          Back
        </k-button>
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="handleResumeSubscription"
        >
          Confirm
        </k-button>
      </template>
    </subscription-modal>

    <subscription-modal
      :isModalShowing="isShowingModalCancelSubscription"
      @close="isShowingModalCancelSubscription = false"
    >
      <template #description>
        Your subscription will be active until
        {{ subscription.nextChargeDate }}. You can always resume your
        subscription until {{ subscription.nextChargeDate }}
      </template>
      <template #action>
        <div>
          <div style="text-align: left" class="mb-1">
            We’re sorry to see you go. If you could, please share your thoughts
            about your experience with us in this survey. Your feedback would
            greatly help us in improving our services.
          </div>

          <div style="text-align: left" class="mt-5 mb-1">
            Why do you choose to cancel your subscription? Please tell us
          </div>
          <div
            v-for="item in CANCELLATION_REASONS"
            :key="item.value"
            class="dropdown-item"
          >
            <input
              class="k-checkbox k-checkbox-md k-rounded-md"
              type="checkbox"
              :id="item.value"
              :value="item.value"
              v-model="formData.reasons"
            />
            <label
              :for="item.value"
              class="text-white"
              v-text="item.label"
            ></label>
          </div>

          <k-textarea
            v-show="formData.reasons.includes(REASONS.OTHER)"
            style="height: auto"
            v-model="formData.otherReason"
            :rows="5"
            maxLength="500"
          />
        </div>

        <k-button
          class="me-2"
          @click="handleCancelSubscription"
          :disabled="disabledConfirmButton"
        >
          Confirm
        </k-button>
      </template>
    </subscription-modal>

    <subscription-modal
      :isModalShowing="isShowingModalSuccess"
      @close="isShowingModalSuccess = false"
    >
      <template #description>
        Your subscription has been updated successfully
      </template>
      <template #action>
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="$router.push('/manageUsers')"
        >
          Continue
        </k-button>
      </template>
    </subscription-modal>
  </form-container>
</template>

<script>
import SeatsForm from '@/components/tenantAdmin/seatsForm.vue';
import RegionsForm from '@/components/tenantAdmin/regionsForm.vue';
import { mapActions, mapGetters } from 'vuex';
import FormContainer from '@/components/tenantAdmin/formContainer.vue';
import SubscriptionModal from '@/components/tenantAdmin/components/SubscriptionModal/SubscriptionModal.vue';
import getUserSubscriptionMixin from '../../mixins/getUserSubscription';
import { REASONS, CANCELLATION_REASONS } from './constants';

export default {
  components: {
    FormContainer,
    RegionsForm,
    SeatsForm,
    SubscriptionModal
  },
  mixins: [getUserSubscriptionMixin],
  data: function () {
    return {
      isShowingModalCancelSubscription: false,
      isShowingModalResumeSubscription: false,
      isShowingModalSuccess: false,

      formData: {
        reasons: [],
        otherReason: ''
      }
    };
  },
  mounted() {
    this.CANCELLATION_REASONS = CANCELLATION_REASONS;
    this.REASONS = REASONS;
  },
  computed: {
    ...mapGetters('fastspring', [
      'selectedBundle',
      'isSubscribed',
      'subscription',
      'isCancelingSubscription'
    ]),
    formTitle() {
      return `Your Order (${(this.selectedBundle || {}).display} Plan)`;
    },
    disabledConfirmButton() {
      if (this.formData.reasons.includes(this.REASONS.OTHER)) {
        return !this.formData.otherReason;
      }

      return this.formData.reasons.length === 0;
    }
  },
  methods: {
    ...mapActions('fastspring', ['cancelSubscription', 'resumeSubscription']),
    async handleCancelSubscription() {
      await this.cancelSubscription({
        reasons: this.formData.reasons.toString().split(',').join(';'),
        otherReason: this.formData.otherReason
      });
      await this.getSubscriptionInfo();
      this.isShowingModalCancelSubscription = false;
      this.isShowingModalSuccess = true;
    },
    async handleResumeSubscription() {
      await this.resumeSubscription();
      await this.getSubscriptionInfo();
      this.isShowingModalResumeSubscription = false;
      this.isShowingModalSuccess = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-form-container {
  flex: 2;
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .header-icon-order {
      width: 24px;
      height: 24px;
    }

    .form-container-body {
      padding: 24px;
      display: flex;
      flex-direction: column;
    }
  }
}

.modify-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 6px;
}

.modify-btn {
  width: 292px;
  height: 42px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.dropdown-item {
  text-align: left;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background: transparent;
  }

  label {
    cursor: pointer;
    margin-left: 8px;
  }
}
</style>
