var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"row dashboard__panel"},[_c('div',{staticClass:"d-flex align-items-center dashboard__panel--header"},[(_vm.isSectorRisk)?_c('span',{staticClass:"col-10 dashboard__panel--header--title"},[_vm._v("Sector Risk Distribution")]):_c('span',{staticClass:"col-10 dashboard__panel--header--title"},[_vm._v("Risk Distribution")]),_c('div',{staticClass:"col-2 d-flex justify-content-end align-items-center"},[_c('span',{staticClass:"dashboard__panel--header--title pe-1"},[_vm._v(_vm._s(_vm.year))]),(_vm.isSectorRisk && _vm.popupsMap[_vm.POPUP_ID.distributionSector])?_c('span',{staticClass:"d-flex align-center k-link px-1",on:{"click":_vm.showSectorRiskPopup}},[_c('icon-info')],1):(!_vm.isSectorRisk && _vm.popupsMap[_vm.POPUP_ID.distributionTotal])?_c('span',{staticClass:"d-flex align-center k-link px-1",on:{"click":_vm.showRiskPopup}},[_c('icon-info')],1):_vm._e()])]),(
          _vm.chart.ticker != null &&
          _vm.chart.chart.x !== undefined &&
          _vm.chart.chart.y !== undefined &&
          _vm.isSectorRisk
        )?_c('div',[(_vm.chart.ticker.rankPercentWithinSector == null)?_c('div',{staticClass:"row align-items-center justify-content-center",staticStyle:{"height":"310px"}},[_vm._v(" No data available for this year ")]):[_c('div',{staticClass:"row px-2 mx-0",staticStyle:{"height":"50px"}},[_c('div',{staticClass:"dashboard--xsmall px-0"},[_c('icon-circle-green'),_c('span',{staticClass:"text-uppercase ps-2"},[_vm._v(_vm._s(_vm.chart.ticker.sector_name))])],1),_c('div',{staticClass:"dashboard--xsmall px-0"},[_c('icon-circle-red'),_c('span',{staticClass:"text-uppercase ps-2"},[_vm._v(_vm._s(_vm.chart.ticker.name))])],1)]),(
              (_vm.riskData != null || _vm.sectorRiskData != null) && _vm.category != null
            )?_c('div',{staticClass:"row dashboard__row px-0 mx-0"},[_c('div',{staticClass:"ms-1 ps-0"},[(_vm.loadChart == true)?_c('chart-custom',{attrs:{"data":_vm.chartData,"color":_vm.chartColor,"size":_vm.chartSize,"point":_vm.redPoint,"pointerLine":_vm.pointerLine,"category":_vm.category,"fontSize":''}}):_vm._e()],1),_c('div',{staticClass:"row dashboard--stack-top justify-content-center text-white"},[_vm._v(" "+_vm._s(_vm.chart.ticker.rankPercentWithinSector == null ? 'N/A' : Math.round(_vm.chart.ticker.rankPercentWithinSector * 100))+" percentile ")])]):_vm._e(),(_vm.showSRP && _vm.popupsMap[_vm.POPUP_ID.distributionSector])?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"dashboard__popup dashboard__sector-risk-popup dashboard__panel--overflow"},[_c('div',{staticClass:"row pb-4"},[_c('div',{staticClass:"col-1 pt-2"},[_c('icon-info-popup')],1),_c('div',{staticClass:"col-9 header d-flex justify-content-start pt-1"},[_vm._v(" Sector Risk Score Distribution ")]),_c('div',{staticClass:"col-2 d-flex justify-content-end",on:{"click":_vm.hideSectorRiskPopup}},[_c('icon-close2')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 gray"},[_vm._v(" "+_vm._s(_vm.popupsMap[_vm.POPUP_ID.distributionSector])+" ")])])]):_vm._e()]],2):_vm._e(),(
          _vm.chart.ticker != null &&
          _vm.chart.chart.x !== undefined &&
          _vm.chart.chart.y !== undefined &&
          !_vm.isSectorRisk
        )?_c('div',[(_vm.chart.ticker.rankPercent == null)?_c('div',{staticClass:"row align-items-center justify-content-center",staticStyle:{"height":"310px"}},[_vm._v(" No data available for this year ")]):[_c('div',{staticClass:"row px-2 mx-0",staticStyle:{"height":"50px"}},[_c('div',{staticClass:"dashboard--xsmall px-0"},[_c('icon-circle-gray2'),_c('span',{staticClass:"text-uppercase ps-2"},[_vm._v(_vm._s(_vm.chart.ticker.region_name))])],1),_c('div',{staticClass:"dashboard--xsmall px-0"},[_c('icon-circle-red'),_c('span',{staticClass:"text-uppercase ps-2"},[_vm._v(_vm._s(_vm.chart.ticker.name))])],1)]),(
              (_vm.riskData != null || _vm.sectorRiskData != null) && _vm.category != null
            )?_c('div',{staticClass:"row dashboard__row px-0 mx-0"},[_c('div',{staticClass:"ms-1 ps-0"},[(_vm.loadChart == true)?_c('chart-custom',{attrs:{"data":_vm.chartData,"color":_vm.chartColor,"size":_vm.chartSize,"point":_vm.redPoint,"pointerLine":_vm.pointerLine,"category":_vm.category,"fontSize":''}}):_vm._e()],1),_c('div',{staticClass:"dashboard--stack-top d-flex justify-content-center text-white"},[_vm._v(" "+_vm._s(_vm.chart.ticker.rankPercent == null ? 'N/A' : Math.round(_vm.chart.ticker.rankPercent * 100))+" percentile ")])]):_vm._e(),(_vm.showRP && _vm.popupsMap[_vm.POPUP_ID.distributionTotal])?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"dashboard__popup dashboard__risk-popup dashboard__panel--overflow"},[_c('div',{staticClass:"row pb-4"},[_c('div',{staticClass:"col-1 pt-2"},[_c('icon-info-popup')],1),_c('div',{staticClass:"col-9 header d-flex justify-content-start pt-1"},[_vm._v(" Risk Score Distribution ")]),_c('div',{staticClass:"col-2 d-flex justify-content-end",on:{"click":_vm.hideRiskPopup}},[_c('icon-close2')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 gray"},[_vm._v(" "+_vm._s(_vm.popupsMap[_vm.POPUP_ID.distributionTotal])+" ")])])]):_vm._e()]],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }