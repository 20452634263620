<template>
  <div>
    <div class="row header align-items-center mb-6">
      <div class="col-6">
        <h3>Manage User Creation Requests</h3>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showAddNewUserDialog"
        >
          Add User Request
        </k-button>

        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showUpdateLucaDateDialog"
        >
          Update Existing User Luca Start/End Date
        </k-button>
      </div>
    </div>

    <k-grid
      :data-items="dataResult"
      :pageable="true"
      :sortable="true"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:exchange_country="{ props }">
        <td :title="props.dataItem.regionName">
          {{ props.dataItem.regionName | formatRegion }}
        </td>
      </template>

      <template v-slot:displayName="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{
              props.dataItem.personList
                .map(({ displayName }) => displayName)
                .join(', ')
            }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:email="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{ props.dataItem.personList.map(({ email }) => email).join(', ') }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:lucaAccessible="{ props }">
        <td>
          <p
            class="mb-0"
            v-if="
              props.dataItem.personList && props.dataItem.personList.length > 0
            "
          >
            {{ props.dataItem.personList[0].lucaAccessible ? 'Yes' : 'No' }}
          </p>
          <p class="mb-0" v-else>N/A</p>
        </td>
      </template>

      <template v-slot:actionTemplate="{ props }">
        <td>
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditDialog(props.dataItem)"
              >Edit User Request</k-button
            >
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showDeleteConfirmationDialog(props.dataItem)"
              >Delete User Request</k-button
            >
          </actions-button>
        </td>
      </template>
    </k-grid>

    <k-dialog
      v-if="dialog.show"
      :title="' '"
      @close="closeDialog"
      class="k-dialog-wrapper--large"
    >
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit="save">
        <div
          style="
            overflow-y: auto;
            max-height: 500px;
            overflow-x: hidden;
            margin-bottom: 16px;
          "
        >
          <div class="row">
            <div class="col-12 mb-3">
              Select Tenant
              <div>
                <k-dropdown-native
                  :data-items="tenantList"
                  :text-field="'companyId'"
                  :data_item-key="'id'"
                  :value="selectedTenant"
                  @change="changeSelectedTenant"
                >
                </k-dropdown-native>
              </div>
            </div>
          </div>

          <div v-if="selectedTenant">
            <div
              class="p-2 border rounded mb-3"
              v-if="entity.personList && entity.personList.length > 0"
            >
              <template v-for="(person, index) in entity.personList">
                <div class="row" :key="index">
                  <div class="col-4">
                    User Name
                    <k-inputs
                      v-model="entity.personList[index].displayName"
                      name="displayName"
                      :required="true"
                      placeholder="Eg. John Doe"
                    />
                  </div>

                  <div class="col-4">
                    Email
                    <k-inputs
                      v-model="entity.personList[index].email"
                      name="email"
                      :required="true"
                      placeholder="Eg. john.doe@mail.com"
                      :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
                    />
                  </div>

                  <div class="col-3">
                    Access to Luca
                    <div>
                      <k-dropdown-native
                        :data-items="accessOptions"
                        :text-field="'text'"
                        :data_item-key="'id'"
                        :value="entity.personList[index].lucaAccessible"
                        @change="changeLucaAccessible($event, index)"
                      >
                      </k-dropdown-native>
                      <div
                        v-if="
                          errors.lucaAccessible && errors.lucaAccessible[index]
                        "
                        style="color: red"
                      >
                        {{ errors.lucaAccessible[index] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-1 align-self-center">
                    <k-button
                      type="button"
                      class="is-small"
                      @click="deletePersonList(index)"
                      :disabled="entity.personList.length == 1"
                    >
                      <icon-trash />
                    </k-button>
                  </div>
                </div>
              </template>

              <div class="d-flex mb-3">
                <k-button
                  type="button"
                  class="is-small"
                  theme-color="primary"
                  @click="addPersonList"
                >
                  Add User
                </k-button>
                <div class="ms-2">
                  <input
                    id="list"
                    type="file"
                    hidden
                    ref="list"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="uploadUserList"
                  />
                  <k-button
                    class="is-small"
                    type="button"
                    theme-color="primary"
                    fill-mode="solid"
                    @click="$refs.list.click()"
                  >
                    Upload User List
                  </k-button>
                </div>
                <k-button class="is-small ms-2" type="button" fill-mode="solid">
                  <a
                    class="k-button-link"
                    href="/upload-user-template.xlsx"
                    download
                    >Download User List Template</a
                  >
                </k-button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                Company Base Time Zone
                <div>
                  <k-dropdown-native
                    :data-items="baseCountryTimezoneOptions"
                    :text-field="'text'"
                    :data_item-key="'id'"
                    :value="entity.country"
                    @change="changeCompanyBaseCountryTimeZone"
                  >
                  </k-dropdown-native>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                Start Access
                <k-date-picker
                  v-model="entity.startDate"
                  name="startDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.startDate && { borderColor: 'red' }"
                />
                <div v-if="errors.startDate" style="color: red">
                  {{ errors.startDate }}
                </div>
                <div v-if="startDateInUTC()">
                  This user will be created at {{ startDateInUTC() }} UTC+0
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                End Access
                <k-date-picker
                  v-model="entity.endDate"
                  name="endDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.endDate && { borderColor: 'red' }"
                />
                <div v-if="errors.endDate" style="color: red">
                  {{ errors.endDate }}
                </div>
                <div v-if="endDateInUTC()">
                  This user will be disabled at {{ endDateInUTC() }} UTC+0
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog
      v-if="updateLucaDateDialog.show"
      :title="' '"
      @close="closeLucaDialog"
    >
      <div class="row mb-3">
        <div
          class="col-12 text-center"
          v-html="updateLucaDateDialog.title"
        ></div>
      </div>
      <form @submit="saveStartEndDate">
        <div
          style="
            overflow-y: auto;
            max-height: 500px;
            overflow-x: hidden;
            margin-bottom: 16px;
          "
        >
          <div class="row">
            <div class="col-12 mb-3">
              Select Tenant
              <div>
                <k-dropdown-native
                  :data-items="tenantList"
                  :text-field="'companyId'"
                  :data_item-key="'id'"
                  :value="selectedTenant"
                  @change="fetchUsersOfTenant"
                >
                </k-dropdown-native>
              </div>
            </div>
          </div>

          <template v-if="selectedTenant">
            <div class="col-12 mb-3">
              Select Users
              <k-custom-multiselect
                placeholder="Select"
                v-model="selectedPersonList"
                dataItem="selectedPersonList"
                :source="personList"
                :customSelected="selectedPersonList"
                textField="email"
                dataItemKey="email"
                :name="'personList'"
                class="mb-0"
                :style="errors.personList && { borderColor: 'red' }"
              ></k-custom-multiselect>
              <span v-if="errors.personList" style="color: red">{{
                errors.personList
              }}</span>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                Company Base Time Zone
                <div>
                  <k-dropdown-native
                    :data-items="baseCountryTimezoneOptions"
                    :text-field="'text'"
                    :data_item-key="'id'"
                    :value="entity.country"
                    @change="changeCompanyBaseCountryTimeZone"
                  >
                  </k-dropdown-native>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                Start Access
                <k-date-picker
                  v-model="entity.startDate"
                  name="startDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.startDate && { borderColor: 'red' }"
                />
                <div v-if="errors.startDate" style="color: red">
                  {{ errors.startDate }}
                </div>
                <div v-if="startDateInUTC()">
                  This user will have access to Luca at
                  {{ startDateInUTC() }} UTC+0
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                End Access
                <k-date-picker
                  v-model="entity.endDate"
                  name="endDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.endDate && { borderColor: 'red' }"
                />
                <div v-if="errors.endDate" style="color: red">
                  {{ errors.endDate }}
                </div>
                <div v-if="endDateInUTC()">
                  This user will lose access to Luca at
                  {{ endDateInUTC() }} UTC+0
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeLucaDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog
      v-if="deleteConfirmationDialog.show"
      :title="' '"
      @close="closeDeleteConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to delete
            {{ deleteConfirmationDialog.dataItem.organizationName }}?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDeleteConfirmationDialog"
            >Close</k-button
          >
          <k-button
            theme-color="primary"
            @click="
              deleteRequest(deleteConfirmationDialog.dataItem.featureRequestId)
            "
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>

    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { format } from 'date-fns';
import ActionsButton from './components/actionsButton.vue';
import cloneDeep from 'lodash/cloneDeep';
import CustomMultiSelect from '../form/k-custom-multiselect';
import timeZoneMixin from './mixins/timeZoneMixin';
import readXlsxFile from 'read-excel-file';

export default {
  components: {
    ActionsButton,
    'k-custom-multiselect': CustomMultiSelect
  },
  mixins: [timeZoneMixin],
  data() {
    return {
      tRegion: [],
      dRegion: null,
      entityList: [],
      entity: {
        map: {}
      },
      skip: 0,
      take: 10,
      filter: null,
      columns: [
        { field: 'organizationName', title: 'Organization Name' },
        { field: 'organizationUrl', title: 'Organization URL' },
        { field: 'displayName', title: 'User Name', cell: 'displayName' },
        { field: 'email', title: 'Email', cell: 'email' },
        {
          field: 'lucaAccessible',
          title: 'Luca Accessible',
          cell: 'lucaAccessible'
        },
        { field: 'startDate', title: 'Access Start Date' },
        { field: 'endDate', title: 'Access End Date' },
        { field: '', title: '', width: 200, cell: 'actionTemplate' }
      ],
      dataResult: [],
      allRegion: [],
      dialog: {
        show: false,
        title: null
      },
      updateLucaDateDialog: {
        show: false,
        title: null
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      tenantList: [],
      selectedTenant: null,
      refetchInterval: null,
      showSelectRegion: true,
      accessOptions: [
        { id: true, text: 'Yes' },
        { id: false, text: 'No' }
      ],
      originalEntity: null,
      errors: {},
      personList: [],
      selectedPersonList: []
    };
  },
  watch: {
    tRegion(value) {
      if (value.length == 0) {
        this.dRegion = null;
      } else {
        this.dRegion = ' ';
      }
    },
    'entity.startDate'(startDate) {
      this.validateForm({ ...this.entity, startDate });
    },
    'entity.endDate'(endDate) {
      this.validateForm({ ...this.entity, endDate });
    },
    'entity.personList'(personList) {
      this.validateForm({ ...this.entity, personList });
    },
    'entity.country'(country) {
      this.validateForm({ ...this.entity, country });
    },
    selectedPersonList() {
      this.validateForm({ ...this.entity });
    }
  },
  async mounted() {
    await this.getRequestedTenantList();
    await this.getTenantList();
    this.getTimezoneOptions();
    this.this.popupNotificationWidget =
      this.$refs.popupNotification.kendoWidget();

    if (this.$route.query && this.$route.query.page) {
      const currentPageSkip = this.take * (Number(this.$route.query.page) - 1);

      if (this.dataResult.total > currentPageSkip) {
        this.skip = this.take * (Number(this.$route.query.page) - 1);
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });

        return;
      }

      this.$router.push({
        name: this.$route.name
      });
    }

    this.setRefetchInterval();
  },
  unmounted() {
    clearInterval(this.refetchInterval);
  },
  methods: {
    changeLucaAccessible(event, index) {
      this.entity = {
        ...this.entity,
        personList: this.entity.personList.map((person, i) => ({
          ...person,
          lucaAccessible: i === index ? event.value : person?.lucaAccessible
        }))
      };
    },
    changeSelectedTenant(event) {
      if (event.value.id) {
        this.selectedTenant = event.value;
        this.setTenantDetail({
          ...event.value
        });
        this.triggerSelectRegion();
        return;
      }
      this.selectedTenant = null;
      this.entity = {};
      this.triggerSelectRegion();
    },
    async fetchUsersOfTenant(event) {
      this.changeSelectedTenant(event);
      this.selectedPersonList = [];

      if (!this.selectedTenant) return;

      const { companyId } = this.selectedTenant || {};
      if (!companyId) return;

      try {
        const response = await axios.post('/person/list', {
          tenantId: companyId
        });
        this.personList = response.data?.listUser;
      } catch (e) {
        console.log(e);
      }
    },
    triggerSelectRegion() {
      this.showSelectRegion = false;
      this.$nextTick(() => {
        this.showSelectRegion = true;
      });
    },
    setRefetchInterval() {
      this.refetchInterval = setInterval(() => {
        this.getRequestedTenantList();
      }, 60000 * 5);
    },
    selectedRegion(value) {
      this.entity.region = value;
    },
    async getRequestedTenantList() {
      try {
        const response = await axios.get('/tenant/request-future-create/list');

        this.entityList = response.data?.filter(({ requestType }) =>
          ['LUCA', 'USER'].includes(requestType)
        );
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getTenantList() {
      try {
        const response = await axios.post('/tenant/list');
        this.tenantList = [{ id: '', companyId: 'None' }, ...response.data];
      } catch (e) {
        console.log(e);
      }
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(this.entityList, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });

      this.$router.push({
        name: this.$route.name,
        query: { page: Math.floor(this.skip / this.take) + 1 }
      });
    },
    rowClick() {
      this.windowVisible = true;
    },
    search() {},
    goTo(props) {
      if (props.dataItem) {
        this.$router.push('tenant-request/' + props.dataItem.featureRequestId);
      }
    },
    async fetchAllregion() {
      const response = await axios.post('/dashboard/allRegion');
      this.allRegion = response.data;
    },
    async showAddNewUserDialog() {
      await this.fetchAllregion();
      this.entity.allRegion = this.allRegion;
      this.dialog.title = 'Add Request';
      this.dialog.show = true;
    },
    async showUpdateLucaDateDialog() {
      await this.fetchAllregion();
      this.entity.allRegion = this.allRegion;
      this.updateLucaDateDialog.title = 'Update Luca Start/End Date';
      this.updateLucaDateDialog.show = true;
    },
    async showEditDialog(dataItem) {
      await this.fetchAllregion();
      this.setTenantDetail(dataItem);
      this.entity.startDate = this.convertTimezoneToUTC(
        dataItem.startDate,
        dataItem.country
      );
      this.entity.endDate = this.convertTimezoneToUTC(
        dataItem.endDate,
        dataItem.country
      );
      if (dataItem.companyId) this.selectedTenant = dataItem;

      if (dataItem.requestType == 'LUCA') {
        this.updateLucaDateDialog.title = 'Update Luca Start/End Date';
        this.updateLucaDateDialog.show = true;
        this.selectedPersonList = dataItem.personList;
        return;
      }

      this.dialog.title = 'Edit Request';
      this.dialog.show = true;
    },
    setTenantDetail(dataItem) {
      let selectedRegion = dataItem.selectedRegion;
      this.entity = cloneDeep(dataItem);
      this.entity.allRegion = this.allRegion;
      this.entity.region = selectedRegion;
      this.entity.startDate = null;
      this.entity.endDate = null;
      this.entity.country = this.baseCountryTimezoneOptions.find(
        ({ id }) => id === dataItem.country
      );

      if (this.entity.personList && this.entity.personList.length > 0) {
        this.entity = {
          ...this.entity,
          personList: this.entity.personList.map((person) => ({
            ...person,
            lucaAccessible: this.accessOptions.find(
              (option) => option.id === person.lucaAccessible
            )
          }))
        };
      } else {
        this.entity = {
          ...this.entity,
          personList: []
        };

        this.$nextTick(() => {
          this.addPersonList();
        });
      }
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
      this.selectedTenant = null;
    },
    closeLucaDialog() {
      this.updateLucaDateDialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
      this.selectedTenant = null;
    },
    save(event) {
      event.preventDefault();

      const isFormValid = this.validateForm(this.entity);
      if (!isFormValid) return;

      this.saving();
    },
    async saveStartEndDate(event) {
      event.preventDefault();

      const isFormValid = this.validateForm(this.entity);
      if (!isFormValid) return;

      try {
        this.originalEntity = cloneDeep(this.entity);
        let formData = cloneDeep(this.entity);
        formData = [];
        formData = [...this.tRegion];
        let regionsWithName = [];

        await this.fetchAllregion();

        regionsWithName = [...this.allRegion];
        regionsWithName = regionsWithName.filter((reg) =>
          formData.includes(reg.exchange_countryID)
        );

        // Send just region id to backend
        this.entity.region = [];
        this.entity.selectedRegion = [];
        regionsWithName.forEach((reg) => {
          let id = parseInt(reg.exchange_countryID);
          this.entity.region.push(id);
          this.entity.selectedRegion.push(reg);
        });

        if (this.entity.selectAllRegions) {
          this.entity.regionName = 'All';
        } else {
          this.entity.regionName = this.entity.selectedRegion
            .map(({ exchange_country }) => exchange_country)
            .join(', ');
        }

        this.entity.requestType = 'LUCA';

        // eslint-disable-next-line no-unused-vars
        const { id, ...entity } = this.entity || {};

        const newEntity = cloneDeep(entity);

        newEntity.country = newEntity.country.id;
        newEntity.startDate = this.convertTimezone(
          newEntity.startDate,
          newEntity.country
        ).toISOString();
        newEntity.endDate = this.convertTimezone(
          newEntity.endDate,
          newEntity.country
        ).toISOString();

        newEntity.personList = this.selectedPersonList.map((email) => ({
          ...this.personList.find((person) => person.email === email),
          lucaAccessible: true
        }));

        const tenantResponse = await axios.post(
          '/tenant/request-future-create',
          newEntity
        );

        if (tenantResponse.status == 200) {
          this.closeLucaDialog();
          await this.getRequestedTenantList();
          clearInterval(this.refetchInterval);
          this.setRefetchInterval();
        }
      } catch (e) {
        console.log(e);
        this.entity = cloneDeep(this.originalEntity);
      }
    },
    validateForm(values) {
      const { startDate, endDate, personList, country } = values || {};

      this.errors = {};

      if (startDate && endDate) {
        if (
          this.convertTimezone(startDate, country?.id || country) < new Date()
        ) {
          this.errors['startDate'] = 'Start Date cannot in the past';
        }
        if (
          this.convertTimezone(endDate, country?.id || country) < new Date()
        ) {
          this.errors['endDate'] = 'End Date cannot in the past';
        }
        if (endDate <= startDate) {
          this.errors['startDate'] =
            'Start Date cannot after or equal End Date';
        }
      }

      for (const key in personList) {
        // eslint-disable-next-line no-prototype-builtins
        if (!personList[key].hasOwnProperty('lucaAccessible')) {
          this.errors['lucaAccessible'] = {
            ...this.errors.lucaAccessible,
            [key]: 'Please select Access to Luca'
          };
        }
      }

      if (this.updateLucaDateDialog.show === true) {
        delete this.errors['lucaAccessible'];

        if (!this.selectedPersonList || this.selectedPersonList.length == 0)
          this.errors['personList'] = 'Please select at least one user';
      }

      return Object.values(this.errors).length === 0;
    },
    addPersonList() {
      this.entity = {
        ...this.entity,
        personList: this.entity.personList.concat([{}])
      };
    },
    deletePersonList(index) {
      this.entity.personList.splice(index, 1);
    },
    async saving() {
      try {
        this.originalEntity = cloneDeep(this.entity);
        let formData = cloneDeep(this.entity);
        formData = [];
        formData = [...this.tRegion];
        let regionsWithName = [];

        await this.fetchAllregion();

        regionsWithName = [...this.allRegion];
        regionsWithName = regionsWithName.filter((reg) =>
          formData.includes(reg.exchange_countryID)
        );

        // Send just region id to backend
        this.entity.region = [];
        this.entity.selectedRegion = [];
        regionsWithName.forEach((reg) => {
          let id = parseInt(reg.exchange_countryID);
          this.entity.region.push(id);
          this.entity.selectedRegion.push(reg);
        });

        if (this.entity.selectAllRegions) {
          this.entity.regionName = 'All';
        } else {
          this.entity.regionName = this.entity.selectedRegion
            .map(({ exchange_country }) => exchange_country)
            .join(', ');
        }

        this.entity.requestType = 'USER';

        // eslint-disable-next-line no-unused-vars
        const { id, ...entity } = this.entity || {};

        const newEntity = cloneDeep(entity);

        newEntity.country = newEntity.country.id;
        newEntity.startDate = this.convertTimezone(
          newEntity.startDate,
          newEntity.country
        ).toISOString();
        newEntity.endDate = this.convertTimezone(
          newEntity.endDate,
          newEntity.country
        ).toISOString();

        newEntity.personList = newEntity.personList.map((person) => ({
          ...person,
          lucaAccessible: person?.lucaAccessible?.id
        }));

        const tenantResponse = await axios.post(
          '/tenant/request-future-create',
          newEntity
        );

        if (tenantResponse.status == 200) {
          this.closeDialog();
          await this.getRequestedTenantList();
          clearInterval(this.refetchInterval);
          this.setRefetchInterval();
        }
      } catch (e) {
        console.log(e);
        this.entity = cloneDeep(this.originalEntity);
      }
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    uploadUserList(event) {
      if (event.target.files.length === 0) return;

      const vm = this;
      const file = event.target.files[0];

      if (file.size > 1024 * 100) {
        this.notificationDialog = {
          show: true,
          title: 'File size is too large',
          message: 'Please upload a file less than 100MB'
        };
        return;
      }

      readXlsxFile(event.target.files[0]).then((rows) => {
        const personList = [];
        // eslint-disable-next-line no-unused-vars
        const [titleRow, ...dataRows] = rows || [];

        for (const column of dataRows) {
          const [displayName, email, lucaAccessible] = column;
          personList.push({
            displayName,
            email,
            lucaAccessible: vm.accessOptions.find(
              ({ id }) => id === lucaAccessible
            )
          });
        }

        vm.entity = {
          ...vm.entity,
          personList
        };
        vm.$refs.list.value = null;
      });
    },
    deleteRequest(featureRequestId) {
      const vm = this;
      axios
        .post(`/tenant/request-future-create/delete/${featureRequestId}`)
        .then((response) => {
          if (response.status == 200) {
            this.getRequestedTenantList();
          } else if (response.status == 204) {
            vm.popupNotificationWidget.show(
              'This request cannot be deleted since the user is still exists. If you want to delete this deleteRequest, please delete all user associated with this deleteRequest.',
              'error'
            );
          } else if (response.status == 202) {
            vm.popupNotificationWidget.show(response.data, 'error');
          }

          this.closeDeleteConfirmationDialog();
        });
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return null;
      return format(value, 'yyyy-MM-dd');
    },
    formatISODate: function (value) {
      if (!value) return null;
      return format(new Date(value), 'yyyy-MM-dd');
    },
    formatRegion: function (value) {
      if (!value) return null;

      const valueToArray = value && value.length > 0 ? value.split(', ') : [];

      return valueToArray.length > 1 ? '(multiple) ..' : value;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  margin: calc(var(--bs-gutter-x) * 0.5);
  border: 1px solid #495057;
  border-radius: 0.25rem;
  width: fit-content;
}
</style>
