// import { parseDomain, ParseResultType } from 'parse-domain';
import Vue from 'vue';

export const setLucaCookies = (accessToken, lucaAccessible) => {
  const now = new Date();
  now.setTime(now.getTime() + 24 * 3600 * 1000);

  Vue.$cookies.set(
    'access_token',
    accessToken,
    now.toUTCString(),
    '/',
    `.transparently.ai`
  );

  if (lucaAccessible) {
    Vue.$cookies.set(
      'lucaAccessible',
      lucaAccessible,
      now.toUTCString(),
      '/',
      `.transparently.ai`
    );
  } else {
    Vue.$cookies.remove('lucaAccessible');
  }
};

export const removeLucaCookies = () => {
  Vue.$cookies.remove('access_token');
  Vue.$cookies.remove('lucaAccessible');
};

export const getUrl = (route) => {
  return `${window.location.origin}/${route}`;
};
