<template>
  <div>
    <div class="row header align-items-center">
      <div class="col-4">
        <h3>Manage Tenants</h3>
      </div>
      <div class="col-8 d-flex justify-content-end">
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showDialog"
        >
          Add Company
        </k-button>
      </div>
    </div>
    <div class="row header align-items-center">
      <div class="d-flex align-items-center search-input">
        <div class="d-flex align-items-center">
          <i @click="doSearch" class="k-icon k-i-search k-icon-12 k-link"></i>
        </div>
        <div class="">
          <k-inputs
            type="text"
            class="unset-background custom-search-field border-0 mb-0"
            v-on:keyup.enter="doSearch"
            v-model="searchInput"
            placeholder="Search by Organization name, Contact Email"
            title="Search by Organization name, Contact Email"
            :maxlength="255"
          />
        </div>
      </div>
    </div>
    <k-grid
      :data-items="dataResult"
      :pageable="pageable"
      :sortable="sortable"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:exchange_country="{ props }">
        <td :title="props.dataItem.regionName">
          {{ props.dataItem.regionName | formatRegion }}
        </td>
      </template>

      <template v-slot:ssp="{ props }">
        <td :title="props.dataItem.ssp">
          {{ props.dataItem.ssp | formatSSP }}
        </td>
      </template>

      <template v-slot:subscriptionEndDate="{ props }">
        <td>
          <p v-if="props.dataItem.subscriptionEndDate" class="mb-0">
            {{ props.dataItem.subscriptionEndDate | formatISODate }}
          </p>
          <p v-else class="mb-0">N/A</p>
        </td>
      </template>

      <template v-slot:subscriptionPaymentDate="{ props }">
        <td>
          <p v-if="props.dataItem.subscriptionPaymentDate" class="mb-0">
            {{ props.dataItem.subscriptionPaymentDate | formatISODate }}
          </p>
          <p v-else class="mb-0">N/A</p>
        </td>
      </template>

      <template v-slot:actionTemplate="{ props }">
        <td>
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditStatusDialog(props.dataItem)"
            >
              Edit Status
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="goTo(props)"
            >
              Manage User
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              v-if="props.dataItem.ssp"
              @click="showSwitchToEnterpriseConfirmationDialog(props.dataItem)"
            >
              Switch to Enterprise
            </k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showEditDialog(props.dataItem)"
              >Edit Tenant</k-button
            >
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1 is-small"
              @click="showDeleteConfirmationDialog(props.dataItem)"
              >Delete Tenant</k-button
            >
          </actions-button>
        </td>
      </template>
    </k-grid>

    <k-dialog v-if="dialog.show" :title="' '" @close="closeDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit="save">
        <div
          style="
            overflow-y: auto;
            max-height: 500px;
            overflow-x: hidden;
            margin-bottom: 16px;
          "
        >
          <div class="row" v-if="entity.companyId && !entity.isStatus">
            <div class="col-12">
              Company ID
              <k-inputs
                v-model="entity.companyId"
                name="companyId"
                :disabled="true"
              />
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12 mb-3">
              Organization Name (*alphanumeric)
              <k-inputs
                v-model="entity.organizationName"
                name="organizationName"
                :required="true"
                placeholder="Eg. TransparentlyAI Pte Ltd"
                class="mb-0"
              />
              <span v-if="errors.organizationName" style="color: red">{{
                errors.organizationName
              }}</span>
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12">
              Organization URL
              <k-inputs
                v-model="entity.organizationUrl"
                name="organizationUrl"
                :required="true"
                placeholder="Eg. www.transparently.ai"
              />
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12">
              Contact Name
              <k-inputs
                v-model="entity.contactName"
                name="contactName"
                :required="true"
                placeholder="Eg. John Doe"
              />
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12">
              Contact Email
              <k-inputs
                v-model="entity.contactEmail"
                name="contactEmail"
                :required="true"
                placeholder="Eg. john-doe@mail.com"
                :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
              />
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12">
              Region Access
              <div>
                <k-inputs
                  v-model="dRegion"
                  name="region"
                  :required="true"
                  style="z-index: -1000"
                />
              </div>
              <div style="position: relative; top: -47px; margin-bottom: -47px">
                <k-custom-multiselect
                  style="border-style: none; padding: 2px"
                  v-model="tRegion"
                  :customSelected="entity.region"
                  placeholder="Select"
                  dataItem="entity.region"
                  :source="entity.allRegion"
                  :autoSelectAll="false"
                  textField="exchange_country"
                  dataItemKey="exchange_countryID"
                  :required="true"
                  name="region"
                  ref="regionSelect"
                ></k-custom-multiselect>
              </div>
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12 mb-3">
              Start Access
              <template v-if="!entity.ssp">
                <k-date-picker
                  v-model="entity.startDate"
                  name="startDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.startDate && { borderColor: 'red' }"
                />
                <span v-if="errors.startDate" style="color: red">{{
                  errors.startDate
                }}</span>
              </template>
              <div v-else>
                {{ entity.startDate | formatISODate }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!entity.isStatus">
            <div class="col-12 mb-3">
              End Access
              <template v-if="!entity.ssp">
                <k-date-picker
                  v-model="entity.endDate"
                  name="endDate"
                  :required="true"
                  class="mb-0"
                  :style="errors.endDate && { borderColor: 'red' }"
                />
                <span v-if="errors.endDate" style="color: red">{{
                  errors.endDate
                }}</span>
              </template>
              <div v-else>
                {{ entity.endDate | formatISODate }}
              </div>
            </div>
          </div>

          <div
            class="row"
            style="margin-bottom: 1rem"
            v-if="
              !entity.isStatus &&
              !!entity.subscriptionEndDate &&
              entity.subscriptionEndDate.toString() !== 'Invalid Date'
            "
          >
            <div class="col-12">
              SSP Expired Date
              <br />
              {{ entity.subscriptionEndDate | formatDate }}
            </div>
          </div>
          <div
            class="row"
            style="margin-bottom: 1rem"
            v-if="
              !entity.isStatus &&
              !!entity.subscriptionPaymentDate &&
              entity.subscriptionPaymentDate.toString() !== 'Invalid Date'
            "
          >
            <div class="col-12">
              Last Payment Date
              <br />
              {{ entity.subscriptionPaymentDate | formatDate }}
            </div>
          </div>
          <div class="row" v-if="entity.isStatus">
            <div class="col-12 mb-3">
              <k-dropdown-native
                :data-items="status"
                :text-field="'text'"
                :data_item-key="'id'"
                :value="entity.status"
                @change="changeStatus"
              >
              </k-dropdown-native>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <k-dialog
      v-if="deleteConfirmationDialog.show"
      :title="' '"
      @close="closeDeleteConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to delete
            {{ deleteConfirmationDialog.dataItem.organizationName }}?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDeleteConfirmationDialog"
            >Close</k-button
          >
          <k-button
            theme-color="primary"
            @click="deleteTenant(deleteConfirmationDialog.dataItem.companyId)"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>

    <k-dialog
      v-if="switchToEnterpriseConfirmationDialog.show"
      :title="' '"
      @close="closeSwitchToEnterpriseConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center">
            Are you sure you want to switch
            {{ switchToEnterpriseConfirmationDialog.dataItem.organizationName }}
            to Enterprise?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-2">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeSwitchToEnterpriseConfirmationDialog"
            >Close</k-button
          >
          <k-button
            theme-color="primary"
            @click="
              switchToEnterprise(
                switchToEnterpriseConfirmationDialog.dataItem.companyId
              )
            "
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>

    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
// import adminTenantForm from "./adminTenantForm";
import { tenantFormValidator } from '@/plugins/validator';
import CustomMultiSelect from '../form/k-custom-multiselect';
import DOMPurify from 'isomorphic-dompurify';
import { format } from 'date-fns';
import ActionsButton from './components/actionsButton.vue';

export default {
  components: {
    // 'admin-tenant-form' : adminTenantForm
    'k-custom-multiselect': CustomMultiSelect,
    ActionsButton
  },
  data() {
    return {
      tRegion: [],
      dRegion: null,
      validateTest: false,
      entityList: [],
      entity: {
        map: {}
      },
      pageable: true,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'companyId', dir: 'asc' }],
      filter: null,
      columns: [
        { field: 'companyId', title: 'Company ID' },
        { field: 'organizationName', title: 'Organization Name' },
        { field: 'organizationUrl', title: 'Organization URL' },
        { field: 'contactName', title: 'Contact Person' },
        { field: 'contactEmail', title: 'Contact Email' },
        {
          field: 'regionName',
          title: 'Regions',
          cell: 'exchange_country'
        },
        {
          field: 'ssp',
          title: 'SSP',
          cell: 'ssp'
        },
        {
          field: 'subscriptionEndDate',
          title: 'SSP Expired',
          cell: 'subscriptionEndDate'
        },
        {
          field: 'subscriptionPaymentDate',
          title: 'Last Payment',
          cell: 'subscriptionPaymentDate'
        },
        { field: 'startDate', title: 'Access Start Date' },
        { field: 'endDate', title: 'Access End Date' },
        { field: '', title: '', width: 200, cell: 'actionTemplate' }
      ],
      dataResult: [],
      gridClickedRow: {},
      status: [
        { id: true, text: 'Active' },
        { id: false, text: 'Disabled' }
      ],
      allRegion: [],
      dialog: {
        show: false,
        title: null
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      switchToEnterpriseConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      manageRegion: {
        show: false,
        dataItem: {}
      },
      tenantFormValidator,
      searchInput: '',
      errors: {}
    };
  },
  watch: {
    tRegion(value) {
      if (value.length == 0) {
        this.dRegion = null;
      } else {
        this.dRegion = ' ';
      }
    },
    'entity.startDate'(startDate) {
      this.validateForm({ ...this.entity, startDate });
    },
    'entity.endDate'(endDate) {
      this.validateForm({ ...this.entity, endDate });
    }
  },
  async mounted() {
    await this.tenantList();
    this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();

    if (this.$route.query && this.$route.query.page) {
      const currentPageSkip = this.take * (Number(this.$route.query.page) - 1);

      if (this.dataResult.total > currentPageSkip) {
        this.skip = this.take * (Number(this.$route.query.page) - 1);
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });

        return;
      }

      this.$router.push({
        name: this.$route.name
      });
    }

    if (this.$route.query && this.$route.query.search) {
      this.searchInput = this.$route.query.search.trim();
      this.tenantList();
    }
  },
  methods: {
    changeStatus(event) {
      this.entity.status = event.value;
    },
    selectedRegion(value) {
      this.entity.region = value;
    },
    async tenantList() {
      try {
        const response = await axios.post(
          this.searchInput
            ? `/tenant/list?searchInput=${this.searchInput}`
            : '/tenant/list'
        );

        this.entityList = response.data;
        this.dataResult = process(this.entityList, {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        });
      } catch (e) {
        console.log(e);
      }
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(this.entityList, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });

      this.$router.push({
        name: this.$route.name,
        query: { page: Math.floor(this.skip / this.take) + 1 }
      });
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    search() {},
    goTo(props) {
      if (props.dataItem) {
        this.$router.push('tenant/' + props.dataItem.companyId);
      }
    },
    async fetchAllregion() {
      const response = await axios.post('/dashboard/allRegion');
      this.allRegion = response.data;
    },
    async showDialog() {
      await this.fetchAllregion();
      this.entity.allRegion = this.allRegion;
      this.dialog.title = 'Add Company';
      this.dialog.show = true;
    },
    async showEditDialog(dataItem) {
      await this.fetchAllregion();
      let selectedRegion = dataItem.selectedRegion;
      this.dialog.title = 'Edit Company';
      this.entity = this.$root.clone(dataItem);
      this.entity.allRegion = this.allRegion;
      this.entity.region = selectedRegion;
      this.entity.isStatus = false;
      this.entity.startDate = new Date(dataItem.startDate);
      this.entity.endDate = new Date(dataItem.endDate);
      this.entity.subscriptionEndDate = dataItem.subscriptionEndDate
        ? new Date(dataItem.subscriptionEndDate)
        : null;
      this.entity.subscriptionPaymentDate = dataItem.subscriptionPaymentDate
        ? new Date(dataItem.subscriptionPaymentDate)
        : null;
      this.dialog.show = true;
    },
    showEditStatusDialog(dataItem) {
      this.dialog.title = 'Edit ' + dataItem.organizationName + ' Status';
      this.entity = this.$root.clone(dataItem);
      this.entity.status = this.status.find((x) => x.id == dataItem.status);
      this.entity.isStatus = true;
      this.dialog.show = true;
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    save(event) {
      event.preventDefault();

      const isFormValid = this.validateForm(this.entity);
      if (!isFormValid) return;

      this.saving();
    },
    validateForm(values) {
      const { startDate, endDate, organizationName } = values || {};
      const cleanOrganizationName = DOMPurify.sanitize(organizationName);

      this.errors = {};

      if (organizationName && organizationName !== cleanOrganizationName) {
        this.errors['organizationName'] =
          'Organization name should not contains any malicious content';
      }

      if (startDate && endDate) {
        if (endDate <= startDate) {
          this.errors['startDate'] =
            'Start Date cannot after or equal End Date';
        }
      }

      return Object.values(this.errors).length === 0;
    },
    async saving() {
      try {
        let formData = this.$root.clone(this.entity);
        // let entity = this.$root.clone(this.entity);
        formData = [];
        formData = [...this.tRegion];
        let regionsWithName = [];

        await this.fetchAllregion();

        regionsWithName = [...this.allRegion];
        regionsWithName = regionsWithName.filter((reg) =>
          formData.includes(reg.exchange_countryID)
        );

        // Send just region id to backend
        this.entity.region = [];
        regionsWithName.forEach((reg) => {
          let id = parseInt(reg.exchange_countryID);
          this.entity.region.push(id);
        });
        // this.entity.region = [...regionsWithName];

        let url = '/tenant/add';
        // if (this.entity.companyId) {
        if (this.entity.id && this.entity.companyId) {
          url = '/tenant/edit';
          // this.entity.region = entity.region;
        }
        if (this.entity.isStatus) {
          this.entity.status = this.entity.status.id;
        }

        this.entity.selectAllRegions = !!this.$refs.regionSelect.selectAll;

        // eslint-disable-next-line no-unused-vars
        const { ssp, subscriptionEndDate, subscriptionPaymentDate, ...entity } =
          this.entity;

        const tenantResponse = await axios.post(url, entity);

        if (tenantResponse.status == 200) {
          this.closeDialog();
          this.tenantList();
        }
      } catch (e) {
        console.log(e);
      }
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },

    showSwitchToEnterpriseConfirmationDialog(dataItem) {
      this.switchToEnterpriseConfirmationDialog.dataItem = dataItem;
      this.switchToEnterpriseConfirmationDialog.show = true;
    },
    closeSwitchToEnterpriseConfirmationDialog() {
      this.switchToEnterpriseConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },

    switchToEnterprise(tenantId) {
      const vm = this;
      axios
        .post(`/tenant/convert-ssp-to-enterprise/${tenantId}`)
        .then((response) => {
          if (response.status == 200) {
            this.tenantList();
          } else if (response.status == 204) {
            vm.popupNotificationWidget.show(
              'This tenant cannot be switched to Enterprise',
              'error'
            );
          } else if (response.status == 202) {
            vm.popupNotificationWidget.show(response.data, 'error');
          }

          this.closeSwitchToEnterpriseConfirmationDialog();
        });
    },

    deleteTenant(tenantId) {
      const vm = this;
      axios.post('/tenant/delete', { tenantId: tenantId }).then((response) => {
        if (response.status == 200) {
          this.tenantList();
        } else if (response.status == 204) {
          vm.popupNotificationWidget.show(
            'This tenant cannot be deleted since the user is still exists. If you want to delete this tenant, please delete all user associated with this tenant.',
            'error'
          );
        } else if (response.status == 202) {
          vm.popupNotificationWidget.show(response.data, 'error');
        }

        this.closeDeleteConfirmationDialog();
      });
    },
    doSearch() {
      this.skip = 0;
      this.$router.push({
        name: this.$route.name,
        query: {
          search: this.searchInput.trim()
        }
      });
      this.searchInput = this.searchInput.trim();
      this.tenantList();
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return null;
      return format(value, 'yyyy-MM-dd');
    },
    formatISODate: function (value) {
      if (!value) return null;
      return format(new Date(value), 'yyyy-MM-dd');
    },
    formatRegion: function (value) {
      if (!value) return null;

      const valueToArray = value && value.length > 0 ? value.split(', ') : [];

      return valueToArray.length > 1 ? '(multiple) ..' : value;
    },
    formatSSP: function (value) {
      if (!value) return 'No';
      return 'Yes';
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  margin: calc(var(--bs-gutter-x) * 0.5);
  border: 1px solid #495057;
  border-radius: 0.25rem;
  width: fit-content;
}
</style>
