import { addMinutes, subMinutes } from 'date-fns';
import ct from 'countries-and-timezones';

export default {
  data() {
    return { baseCountryTimezoneOptions: [] };
  },
  methods: {
    startDateInUTC() {
      if (this.entity.startDate && this.entity.country?.id) {
        return this.formatDate(this.entity.startDate, this.entity.country.id);
      }
      return null;
    },
    endDateInUTC() {
      if (this.entity.endDate && this.entity.country?.id) {
        return this.formatDate(this.entity.endDate, this.entity.country.id);
      }
      return null;
    },
    getTimezoneOptions() {
      this.baseCountryTimezoneOptions = Object.values(ct.getAllTimezones()).map(
        ({ utcOffset, name }) => ({
          id: name,
          text: `${name} (UTC${utcOffset >= 0 ? '+' : '-'}${Math.abs(
            utcOffset / 60
          )})`
        })
      );
    },
    changeCompanyBaseCountryTimeZone(event) {
      this.entity = {
        ...this.entity,
        country: event.value
      };
    },
    formatDate(date, timeZone) {
      const isoDate = this.convertTimezone(date, timeZone).toISOString();
      return `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;
    },
    convertTimezone(date, timeZone) {
      if (!ct.getTimezone(timeZone)) return this.toUTC(new Date(date));

      const offset = ct.getTimezone(timeZone).utcOffset;

      if (offset < 0)
        return addMinutes(this.toUTC(new Date(date)), Math.abs(offset));
      return subMinutes(this.toUTC(new Date(date)), Math.abs(offset));
    },
    convertTimezoneToUTC(date, timeZone) {
      if (!ct.getTimezone(timeZone)) return new Date(date);

      const offset = ct.getTimezone(timeZone).utcOffset;
      if (offset < 0) return subMinutes(new Date(date), Math.abs(offset));
      return addMinutes(new Date(date), Math.abs(offset));
    },
    toUTC(date) {
      return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
      );
    }
  }
};
